/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import Container from '../../components/atoms/Container/container';
import { GET, POST } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import euroFormate from '../../components/Utils/euroFormate';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionFrais from '../../fonctionsRoles/frais';

const ListesGestionFrais = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataFrais, setDataFrais] = useState([]);
    const [tableauFiltre, setTableauFiltre] = useState({
        status: [],
        anneeScolaire: [],
    });
    const reducerFiltreMultiple = useSelector((state) => state.SelectMultiple);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const css = useStylesMaterielUIGlobal();
    const tableHead = [
        { id: 'dateDemande', label: 'Date demande' },
        { id: 'nomPrenomUtilisateur', label: 'Agent' },
        { id: 'periode', label: 'Période' },
        { id: 'kilometrage', label: 'Kilometrage' },
        { id: 'nombreRepas', label: 'Nombre Repas' },
        { id: 'montantDivers', label: 'Montant Divers' },
        { id: 'idFraisStatut', label: 'Statut' },
    ];
    const tableHeadExport = [
        { value: 'dateDemande', label: 'Date demande' },
        { value: 'nomPrenomUtilisateur', label: 'Agent' },
        { value: 'periode', label: 'Période' },
        { value: 'kilometrage', label: 'Kilometrage' },
        { value: 'nombreRepas', label: 'Nombre Repas' },
        { value: 'montantDivers', label: 'Montant Divers' },
        { value: 'libelleFraisStatut', label: 'Statut' },
        { value: 'vehiculePuissance', label: 'Puissance fiscale' },
    ];
    function StatusFrais(props) {
        const color = ['#808080', '#41b5a9', '#2e76b0', '#78cf99', '#f26a44'];
        return (
            <span className="flex alignICenter">
                {props.status === 3 ? (
                    <CustomTooltips title="Valider cette note de frais">
                        <IconButton onClick={() => saveFrais(props)}>
                            <CheckIcon className={css.cVertValidationFrais} />
                        </IconButton>
                    </CustomTooltips>
                ) : (
                    <></>
                )}
                <p className="textCenter cWhite p2 radius10" style={{ backgroundColor: color[props.status - 1] }}>
                    {props.libelleFraisStatut}
                </p>
            </span>
        );
    }

    async function saveFrais(dataFrais) {
        const data = {
            idFrais: dataFrais.data.idFrais,
            fk_fraisStatut: 4,
        };
        const response = await POST('/api/frais/validationFicheFrais', data, {
            redirectOnError: false,
        });

        if (response.success === true) {
            recupDataFrais();
            props.notification({
                message: 'Cette note de frais a été validée',
                status: true,
            });
        }
    }
    async function recupDataFrais() {
        const data = {
            anneeScolaire: reducerFiltre.AnneeScolaire,
            listStatuts: reducerFiltreMultiple.StatusFrais.length === 0 ? null : reducerFiltreMultiple.StatusFrais,
        };
        const response = await POST('/api/utilisateursAdministratifs/listFrais/', data);
        for (let index = 0; index < response.data.length; index++) {
            response.data[index].periode = DateHeureFormat({ date: response.data[index].periode, mois: true });
        }
        setDataFrais(response.data);
    }
    function updatefiltre(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    async function recupFiltre() {
        const fraisStatuts = await GET('/api/frais/fraisStatuts');
        updatefiltre('status', fraisStatuts.data);
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        updatefiltre('anneeScolaire', anneeScolaireFiltre.data);
    }

    useEffect(() => {
        props.filtreMultiple([{ StatusFrais: [2, 3] }]);
        props.filtre([{ AnneeScolaire: user[usurpation].idAnneeScolaire }]);
        props.addNomPage('Gestion des frais');
        recupFiltre();
    }, []);
    useEffect(() => {
        recupDataFrais();
    }, [refreshSimple, refreshMultiple]);
    return (
        <div className="listeContainer">
            <Container>
                <div className="flex alignICenter xs_directionColumn xs_alignIBaseline">
                    <SelectSimple
                        tableau={tableauFiltre.anneeScolaire}
                        inputLabel={'Année Scolaire'}
                        indice={'AnneeScolaire'}
                        classe={[css.w25, css.ml2, css.xs_w90]}
                    ></SelectSimple>
                    <SelectMultiple
                        tableau={tableauFiltre.status}
                        inputLabel={'Statuts'}
                        indice={'StatusFrais'}
                        classe={[css.w25, css.ml2, css.xs_w90]}
                    ></SelectMultiple>
                </div>
                <div className="flex alignICenter">
                    <TableRecherche tableau={dataFrais} className={[css.w40, css.ml2, css.xs_w90, css.mr2]} indice={['nomPrenomUtilisateur']}></TableRecherche>
                    {testDroit(user[usurpation].roleFonction, fonctionFrais.Export_excel) ? (
                        <ExportExcel data={dataFrais} head={tableHeadExport} nomFichier="Liste frais" />
                    ) : null}
                </div>
                <PaginationTable data={props.recherche.fn(dataFrais)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {setData(props.recherche.fn, dataFrais, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    return (
                                        <TableRow key={index} className={css.trbody}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Date demande</div>

                                                <Link to={`/fiche-frais/${row.idFrais}/gestion`}>
                                                    <DateHeureFormat date={row.dateDemande} />
                                                </Link>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Agent</div>
                                                {row.nomPrenomUtilisateur}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Période</div>
                                                {row.periode}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Kilometrage</div>
                                                <span className="floatRight mr5">{row.kilometrage}</span>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Nombre Repas</div>
                                                <span className="floatRight mr5">{row.nombreRepas}</span>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Montant Divers</div>
                                                <span className="floatRight mr10">{euroFormate(row.montantDivers)}</span>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <StatusFrais status={row.idFraisStatut} libelleFraisStatut={row.libelleFraisStatut} data={row} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataFrais)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListesGestionFrais);
