/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';

import { POST } from '../../../components/Utils/requete';
import InfoAgent from '../../../components/organism/InfoAgent/infoAgent';
import Contact from '../../../components/organism/Contact/Contact';

import * as fonctionAgent from '../../../fonctionsRoles/agents';

import 'antd/dist/reset.css';
import '../../../index.css';

const ListeRoleEmployes = ({ idRole }) => {
    const [employes, setEmployes] = useState([]);

    const GetListeEmployes = async () => {
        const data = {
            role: idRole,
            site: null,
            actif: 1,
        };
        const response = await POST('/api/utilisateursAdministratifs/list/', data);

        setEmployes(response.data);
    };

    useEffect(() => {
        GetListeEmployes();
    }, []);

    const columns = [
        {
            title: 'Nom prénom',
            sorter: (a, b) => a.nomPrenom.localeCompare(b.nomPrenom),
            render: (text, props) => (
                <div className="flex spaceBetween alignICenter">
                    <span>{props.nomPrenomUtilisateur}</span>
                    <InfoAgent
                        liste={true}
                        idUtilisateur={props.idUtilisateur}
                        idCours={fonctionAgent.Lien_cours_Liste_Agent}
                        idService={fonctionAgent.Lien_services_Liste_Agent}
                        idProfil={fonctionAgent.Lien_profil}
                    />
                </div>
            ),
        },
        {
            title: 'Email',
            render: (text, props) => <Contact email={props.email} />,
        },
        {
            title: 'Fixe',
            dataIndex: 'directeur',
            render: (text, props) => <Contact email={props.telephoneFixe} />,
        },
        {
            title: 'Mobile',
            dataIndex: 'directeur',
            render: (text, props) => <Contact email={props.telephoneMobile} />,
        },
    ];
    return (
        <div>
            <Table
                columns={columns}
                dataSource={employes}
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['bottomRight'],
                    total: employes?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                }}
            />
        </div>
    );
};

export default ListeRoleEmployes;
