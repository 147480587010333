/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import { ReactComponent as CaretIcon } from '../../../assets/caret.svg';
import { ReactComponent as Deconnexion } from '../../../assets/logout.svg';

import { CSSTransition } from 'react-transition-group';

import Usurpation from '../Usurpation/Usurpation';

function NavMenu(props) {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }
    function deconnection() {
        props.removeUsuration();
        Cookies.remove('user');
        history.push('/connexion');
    }

    function DropdownItem(props) {
        return (
            <span className="menu-item" onClick={props.onClick}>
                <span className="icon-button">{props.leftIcon}</span>
                {props.children}
            </span>
        );
    }
    function NavItem(props) {
        return (
            <ul className="navbar-nav">
                <li className="nav-item">
                    <span className="icon-button" onClick={props.onClick}>
                        {props.icon}
                    </span>
                    <span className="text-button" onClick={props.onClick}>
                        {props.label}
                    </span>
                    {open && props.children}
                </li>
            </ul>
        );
    }
    function noGoToMenu() {
        setActiveMenu('main');
        setOpen(false);
    }
    function ajoutLink(row, index) {
        const link = function (balise) {
            return row.url ? <Link to={row.url}>{balise}</Link> : <>{balise}</>;
        };
        return link(
            <DropdownItem onClick={() => (!row.goToMenu ? noGoToMenu() : setActiveMenu(row.goToMenu))} key={index} leftIcon={row.icone}>
                <span className="fontSize0_8em">{row.label}</span>
            </DropdownItem>,
        );
    }
    function DropdownMenu() {
        return (
            <div className="dropdown" style={{ height: menuHeight + 25 }} ref={dropdownRef}>
                <CSSTransition in={activeMenu === props.structure.dropDown.main.id} timeout={500} unmountOnExit onEnter={calcHeight}>
                    <div className="menu ">{props.structure.dropDown.main.menu.map((row, index) => ajoutLink(row, index))}</div>
                </CSSTransition>

                {props.structure.dropDown.secondary.map((row, index) => {
                    return (
                        <CSSTransition in={activeMenu === row.id} timeout={500} unmountOnExit onEnter={calcHeight}>
                            <div className="menu">
                                <DropdownItem onClick={() => setActiveMenu(props.structure.dropDown.main.id)} leftIcon={<ArrowIcon />}>
                                    <h2>Retour</h2>
                                </DropdownItem>
                                {row.menu.map((row, index) => (
                                    <Link to={row.url} style={{ color: '#fff', textDecoration: 'none' }}>
                                        <DropdownItem
                                            onClick={() => {
                                                setActiveMenu('main');
                                                setOpen(false);
                                            }}
                                            leftIcon={row.icone}
                                        >
                                            {row.label}
                                        </DropdownItem>
                                    </Link>
                                ))}
                            </div>
                        </CSSTransition>
                    );
                })}
            </div>
        );
    }
    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [open]);

    return (
        <nav className="navbar ">
            <NavItem
                icon={<CaretIcon />}
                label={props.structure.label}
                onClick={() => {
                    setOpen(!open);
                    setActiveMenu('main');
                }}
            >
                <DropdownMenu />
            </NavItem>
            <Usurpation />

            <NavItem icon={<Deconnexion />} onClick={() => deconnection()}></NavItem>
        </nav>
    );
}
function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        removeUsuration() {
            dispatch({ type: 'REMOVE_USURPATION' });
        },
    };
}
export default connect(null, mapDispatchToProps)(NavMenu);
