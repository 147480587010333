/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Checkbox,
    Button,
    IconButton,
    TextareaAutosize,
    FormControl,
    Select,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { GET, POST, DELETE } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import setData from '../../../components/organism/Table/setDataTableau';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import LienLivret from '../../../components/organism/LienLivret/lienLivret';
import downloadFile from '../../../components/Utils/downloadFile';
import anneeEnCour_Inscription from '../../../components/Utils/requeteParametre/anneeEnCour_Inscription';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import * as fonctionEleve from '../../../fonctionsRoles/eleve';

const tableHeadScolariteInterne = [
    { id: 'anneeScolaireInterne', label: 'Année scolaire' },
    { id: 'siteInterne', label: 'Site/Enseignant' },
    { id: 'offreInterne', label: 'Offre' },
    { id: 'precisionOffreInterne', label: 'Précision offre' },
    { id: 'infoFMGInterne', label: 'Précision FM-G' },
    { id: 'statutInterne', label: 'Statut' },
    {
        id: 'Commentaire',
        label: 'Commentaire',
    },
];
const filtreScolariteInterne = [
    [
        { label: 'Année Scolaire', indice: 'AnneeScolaire' },
        { label: 'Offre', indice: 'Offre' },
    ],
    [
        { label: 'Site', indice: 'Site' },
        { label: 'Statut', indice: 'Statut' },
    ],
    [
        { label: 'Instrument', indice: 'Instrument' },
        { label: 'Année', indice: 'Annee' },
    ],
    [
        { label: 'Cycles', indice: 'Cycle' },
        { label: 'Enseignant', indice: 'Enseignant' },
    ],
];

const ScolariteInterne = (props) => {
    let { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const dataFiltre = useSelector((state) => state.SelectSimple);
    const refreshPage = useSelector((state) => state.SelectSimple.refreshSimple);
    const css = useStylesMaterielUIGlobal();
    const [checkBoxAbandon, setCheckBoxAbandon] = useState(false);
    const [openModal, setOpenModal] = useState({ open: false, complementaire: 0 });
    const [dataSI, setDataSI] = useState([]);
    const [tableauFiltre, setTableauFiltre] = useState({
        Site: [],
        Offre: [],
        Statut: [],
        AnneeScolaire: [],
        Annee: [],
        Cycle: [],
        Instrument: [],
        Enseignant: [],
        Style: [],
        AnneeEnCours_Inscription: [],
    });
    const [documentCertificat, setDocumentCertificat] = useState({ fileName: '' });
    const [certificat, setCertificat] = useState(false);
    const [listeSIComplementaire, setListeSIComplementaire] = useState([]);
    const [commentaire, setCommentaire] = useState('');

    function CertificatMedical(props) {
        if (props.fichier) {
            return (
                <div className="flex directionColumn">
                    <span> Certificat médical: </span>
                    <span>
                        <Link
                            onClick={() => {
                                downloadFile(
                                    'certificatMedical',
                                    {
                                        fk_scolariteInterne: props.idScolarite,
                                    },
                                    props.fichier,
                                );
                            }}
                        >
                            {props.fichier}
                        </Link>
                    </span>
                </div>
            );
        } else {
            return <></>;
        }
    }
    async function listSIComplementairePossible(idScolarite) {
        let query = `/api/eleves/SI_ListComplementairePossible?fk_eleve=${id}`;
        if (idScolarite) query += `&idScolarite=${idScolarite}`;
        let response = await GET(query);
        setListeSIComplementaire(response.data);
    }
    function changeSIComplementaire(value) {
        setOpenModal((prevState) => ({
            ...prevState,
            idScolariteParent: value,
        }));
    }

    function ComplementairePossible() {
        const indexComplementaire = listeSIComplementaire.findIndex((e) => e.enfant === openModal.idScolarite);
        const indexParent = listeSIComplementaire.findIndex((e) => e.fk_idScolariteParent === openModal.idScolarite);
        const listeSINonComplementaire = listeSIComplementaire.filter(function (e) {
            let row;
            if (!e.enfant && !e.fk_idScolariteParent) row = e;
            return row;
        });

        let listeSI = listeSINonComplementaire;
        if (openModal.fk_idScolariteParent) listeSI = listeSIComplementaire;

        if (indexParent !== -1)
            return (
                <div className="w60 mb2 ml2 xs_w100">
                    <p>
                        Une offre complémentaire est associé à cette scolarité:
                        <br />
                        {listeSIComplementaire[indexParent].libelleOffre}
                    </p>
                </div>
            );
        return (
            <div className="w60 mb2 ml2 xs_w100">
                {listeSI.length === 0 ? (
                    <p>Aucune scolarité disponible pour la complémentaire</p>
                ) : (
                    <>
                        <Checkbox
                            onChange={() =>
                                setOpenModal((prevState) => ({
                                    ...prevState,
                                    complementaire: !openModal.complementaire ? 1 : 0,
                                }))
                            }
                            checked={openModal.complementaire}
                        />
                        <span>Scolarité en complémentaire</span>
                        {indexComplementaire !== -1 ? <p>Offre associé a cette scolarité: {listeSI[indexComplementaire].libelleOffre}</p> : null}
                        <div className="flex w100 alignICenter spaceBetween">
                            <label>Scolarité associé:</label>
                            <FormControl className={css.w80}>
                                <Select
                                    disabled={!openModal.complementaire}
                                    native={true}
                                    onChange={(event) => changeSIComplementaire(event.target.value)}
                                    value={listeSI.idScolarite}
                                >
                                    <option value={''}></option>
                                    {listeSI.map((elem, index) => {
                                        return (
                                            <option key={index} value={elem.idScolarite}>
                                                {elem.libelleOffre}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </>
                )}
            </div>
        );
    }
    function anneeScolariteInterne(idAnnee) {
        if (tableauFiltre.AnneeScolaire.length !== 0) {
            if (openModal) {
                return (
                    <SelectSimple
                        tableau={tableauFiltre.AnneeEnCours_Inscription}
                        indice={'AnneeScolaire'}
                        inputLabel={'Année Scolaire'}
                        classe={css.w100}
                    ></SelectSimple>
                );
            } else {
                let libelleAnnee = '';
                if (idAnnee) {
                    libelleAnnee = tableauFiltre.AnneeScolaire.filter((e) => e.idAnneeScolaire === idAnnee)[0].libelleAnneeScolaire;
                }
                return (
                    <div className="flex directionColumn mb2">
                        <span>Année scolaire</span>
                        <span>{libelleAnnee}</span>
                    </div>
                );
            }
        }
    }
    function Modal() {
        return (
            <DialogCustom
                open={openModal.open}
                onClose={() => {
                    setOpenModal({ open: false, complementaire: 0 });
                    setCertificat(false);
                }}
                children={
                    <>
                        <h3 className="textCenter">Ajout d'une scolarité conservatoire</h3>
                        <div className="flex mb2 xs_directionColumn">
                            {filtreScolariteInterne.map((e, i) => (
                                <div key={i} className="w40 ml2 xs_w90">
                                    {e[0].indice === 'AnneeScolaire' ? (
                                        anneeScolariteInterne(dataFiltre.AnneeScolaire)
                                    ) : (
                                        <SelectSimple
                                            tableau={tableauFiltre[e[0].indice]}
                                            indice={e[0].indice}
                                            inputLabel={e[0].label}
                                            classe={css.w100}
                                        ></SelectSimple>
                                    )}
                                    <SelectSimple
                                        tableau={tableauFiltre[e[1].indice]}
                                        indice={e[1].indice}
                                        inputLabel={e[1].label}
                                        classe={css.w100}
                                        native={true}
                                        disabled={e[1].indice === 'Statut' ? checkBoxAbandon : false}
                                    ></SelectSimple>
                                </div>
                            ))}
                        </div>
                        {tableauFiltre.Style.length > 0 ? (
                            <div className="w60 mb2 ml2 xs_w100">
                                <h4>Dominante ou esthétique</h4>
                                <div className="flex alignICenter xs_directionColumn xs_alignIBaseline">
                                    <SelectSimple
                                        tableau={tableauFiltre.Style}
                                        indice="StyleOffre"
                                        inputLabel="Style"
                                        classe={[css.mb2, css.w40, css.xs_w90]}
                                        native={true}
                                    ></SelectSimple>
                                </div>
                            </div>
                        ) : null}
                        {ComplementairePossible()}
                        {certificat ? (
                            <div className="mb2 ml2">
                                <h4>Certificat médical</h4>
                                <CertificatMedical fichier={openModal.certificat} idScolarite={openModal.idScolarite} />
                                <input
                                    id="raised-button-file"
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        uploadCertificatMedicalSI(event.target.files[0]);
                                    }}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" color="default" component="span">
                                        Ajouter un certificat medical
                                    </Button>
                                    <label className="ml5 cVert32CD32">{documentCertificat.fileName}</label>
                                </label>
                            </div>
                        ) : null}
                        <TextareaAutosize
                            className={css.textareaW40ML2_XSW90XSMT2}
                            rowsMin={5}
                            placeholder="Commentaire"
                            value={commentaire}
                            onChange={(event) => {
                                setCommentaire(event.target.value);
                            }}
                        />
                        <br />
                        <div className="mt2">
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                className={css.ml2}
                                onClick={() => {
                                    savescolariteInterne();
                                }}
                            >
                                Enregistrer
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                className={css.ml2}
                                onClick={() => {
                                    setOpenModal({ open: false, complementaire: 0 });
                                    setCertificat(false);
                                }}
                            >
                                annuler
                            </Button>
                        </div>
                    </>
                }
                fullScreen={true}
            />
        );
    }
    function ViewLibelleOffre(props) {
        let libelle = `${props.complementaire ? '* ' : ''}${props.offre}`;
        if (props.nomStyle) libelle += ` - ${props.nomStyle} ${props.style}`;
        return <span>{libelle} </span>;
    }
    function ViewAnneeDateCreation(props) {
        return (
            <>
                <span className="flex fontSize0_8em">
                    <span>Année Scolaire :</span>
                    <span>{props.annee}</span>
                </span>
                <span className="flex fontSize0_8em">
                    <span>Date d'inscription :</span>
                    <span>{DateHeureFormat({ date: props.date })}</span>
                </span>
            </>
        );
    }
    function Atelier(atelier) {
        atelier = atelier.split('|');
        if (atelier[0] !== '') {
            return (
                <Link to={`/fiche-atelier/${atelier[0]}`} className={css.linkW100MR2}>
                    <p className="textCenter cWhite pr0_4rem pl0_4rem radius4 nomargin mb5" style={{ backgroundColor: 'lightGray', color: 'black' }}>
                        {atelier[1] && atelier[1] !== '' ? atelier[1] : 'Atelier'}
                    </p>
                </Link>
            );
        } else {
            return <></>;
        }
    }
    function Ateliers(props) {
        if (props.ateliers.length > 0) {
            return props.ateliers.map((atelier) => Atelier(atelier));
        } else {
            return <></>;
        }
    }

    async function requeteRecupData() {
        let response = await GET(`/api/eleves/${id}/SI_list`);
        setDataSI(response.data);
        response = await GET('/api/eleves/' + id);
        setCheckBoxAbandon(response.data.dateAbandon ? true : false);
        response = await anneeEnCour_Inscription();
        setTableauFiltre((prevState) => ({
            ...prevState,
            AnneeEnCours_Inscription: response,
        }));
    }
    async function recupTableauFiltre() {
        if (tableauFiltre.AnneeScolaire.length === 0) {
            const requeteFicheEleveGETFiltre = [
                { url: '/api/sites/liste', key: 'Site' },
                { url: '/api/cotisations/offres', key: 'Offre' },
                { url: '/api/ateliers/ateliersStatut', key: 'Statut' },
                { url: '/api/anneesScolaire/list', key: 'AnneeScolaire' },
                { url: '/api/eleves/SI_annees', key: 'Annee' },
                { url: '/api/cotisations/cycles', key: 'Cycle' },
                { url: '/api/cotisations/instruments', key: 'Instrument' },
            ];
            for (let elem = 0; elem < requeteFicheEleveGETFiltre.length; elem++) {
                let requete;
                if (requeteFicheEleveGETFiltre[elem].url !== '/api/cotisations/instruments') {
                    requete = await GET(requeteFicheEleveGETFiltre[elem].url);
                } else {
                    requete = await POST(requeteFicheEleveGETFiltre[elem].url);
                }
                setTableauFiltre((prevState) => ({
                    ...prevState,
                    [requeteFicheEleveGETFiltre[elem].key]: requete.data,
                }));
            }
        }
        const dataEnseignant = {
            fk_anneeScolaire: dataFiltre.AnneeScolaire,
            fk_site: [dataFiltre.Site],
            fk_instrument: [],
        };
        const enseignantFiltre = await POST('/api/utilisateursAdministratifs/enseignants', dataEnseignant);
        setTableauFiltre((prevState) => ({
            ...prevState,
            Enseignant: enseignantFiltre.data,
        }));
    }
    async function filtreStyle() {
        if (dataFiltre.Offre) {
            const response = await GET(`/api/cotisations/${dataFiltre.Offre}?fk_anneeScolaire=${dataFiltre.AnneeScolaire}`, undefined, {
                redirectOnError: false,
            });
            if (response.data.certificatMedical) setCertificat(true);
            setTableauFiltre((prevState) => ({
                ...prevState,
                Style: response.data.styles,
            }));
        }
    }
    async function savescolariteInterne(abandonSI) {
        const idScolarite = openModal.idScolarite === undefined ? 0 : openModal.idScolarite;
        let data = [];
        if (abandonSI) {
            for (let index = 0; index < abandonSI.length; index++) {
                abandonSI[index].fk_eleve = id;
                abandonSI[index].fk_statut = 6;
                data.push(abandonSI[index]);
            }
        } else if (dataFiltre.Statut === '') {
            props.notification({ message: `Le statut dois etre renseigné` });
        } else {
            data.push({
                idScolarite: idScolarite,
                fk_eleve: id,
                fk_anneeScolaire: dataFiltre.AnneeScolaire,
                fk_offre: dataFiltre.Offre,
                fk_site: dataFiltre.Site,
                fk_instrument: dataFiltre.Instrument === '' ? null : dataFiltre.Instrument,
                fk_style: dataFiltre.StyleOffre === '' ? null : dataFiltre.StyleOffre,
                fk_statut: dataFiltre.Statut,
                fk_cycle: dataFiltre.Cycle === '' ? null : dataFiltre.Cycle,
                fk_annee: dataFiltre.Annee === '' ? null : dataFiltre.Annee,
                fk_enseignant: dataFiltre.Enseignant === '' ? null : dataFiltre.Enseignant,
                fk_idScolariteParent: openModal.complementaire === 1 ? openModal.idScolariteParent : null,
                complementairePossible: 0,
                complementaire: openModal.complementaire,
                commentaire: commentaire,
            });
        }

        for (let index = 0; index < data.length; index++) {
            if (data[index].complementaire && !data[index].fk_idScolariteParent && !abandonSI) {
                return props.notification({ message: `Vous devez associer une offre à cette scolarité pour qu'elle soit complementaire` });
            }
            let response = await POST('/api/eleves/' + id + '/scolaritesInterne/' + idScolarite, data[index], {
                redirectOnError: false,
            });
            if (response.success === false) {
                props.notification({ message: `Erreur lors de l'enregistrement : ${response.message}` });
            } else {
                if (documentCertificat.doc) {
                    documentCertificat.doc.append('fk_scolariteInterne', response.data.idScolarite);
                    response = await POST('/api/fichiers/upload/certificatMedical', documentCertificat.doc, {
                        upload: true,
                        redirectOnError: false,
                    });
                    if (!response.success) {
                        props.notification({ message: `Erreur lors de l'nvoi du certificat : ${response.message}` });
                    }
                }
                if (!abandonSI) {
                    requeteRecupData();
                    setOpenModal({ open: false, complementaire: 0 });
                    props.removeFiltre();
                }
            }
        }
    }
    async function uploadCertificatMedicalSI(file) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            setDocumentCertificat();
            return;
        }
        const data = new FormData();
        data.append('file', file);
        setDocumentCertificat({ doc: data, fileName: file.name });
    }
    async function supprimerScolarite(idScolarite) {
        await DELETE(`/api/eleves/${id}/scolaritesInterne/${idScolarite}`, undefined, { redirectOnError: false });
        requeteRecupData();
    }

    useEffect(() => {
        recupTableauFiltre();
        requeteRecupData();
    }, [refreshPage]);
    useEffect(() => {
        filtreStyle();
        ComplementairePossible();
    }, [dataFiltre.Offre]);

    return (
        <div>
            {Modal()}
            <h3 className="none xs_block textCenter mt5">Scolarité Conservatoire</h3>
            <div className="flex alignICenter spaceBetween">
                <p className=" fontLittleSmallGrey">* Scolaire interne complementaire</p>
                {testDroit(user[usurpation].roleFonction, fonctionEleve.Ajout_nouvelle_SI) ? (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={css.ml2}
                        onClick={() => {
                            props.removeFiltre();
                            props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
                            setOpenModal({ open: true, complementaire: 0, commentaire: '', complementairePossible: 0 });
                            listSIComplementairePossible();
                        }}
                        disabled={checkBoxAbandon}
                    >
                        Ajouter une scolarité interne
                    </Button>
                ) : null}
            </div>
            {window.matchMedia('(max-width: 576px)').matches ? (
                <div>
                    <hr></hr>
                    {dataSI.map((row, i) => (
                        <div key={i} className="pl2 pr2 mt2">
                            <ViewLibelleOffre
                                complementaire={row.complementaire}
                                offre={row.libelleOffre}
                                nomStyle={row.libelleNomStyle}
                                style={row.libelleStyle}
                            />
                            <ViewAnneeDateCreation annee={row.libelleAnneeScolaire} date={row.dateCreation} />
                            {row.nomPrenomEnseignant ? (
                                <p className="flex directionColumn">
                                    <span>Enseignant:</span>
                                    <span>{row.nomPrenomEnseignant}</span>
                                </p>
                            ) : null}
                            <div className="flex ">
                                {row.libelleSite ? <span className="mr2">{`Site: ${row.libelleSite}`}</span> : null}
                                <span>{`Statut: ${row.libelleStatut}`}</span>
                            </div>
                            {row.fk_cycle === null ? (
                                ''
                            ) : (
                                <div className="flex ">
                                    <span className="mr2">{`Cycle ${row.fk_cycle}`}</span>
                                    <span>{`Année ${row.fk_annee}`}</span>
                                </div>
                            )}
                            <div className="flex directionColumn mt2 ml2">
                                <Ateliers ateliers={row.ateliers ? row.ateliers.split('|-|') : []} />
                            </div>
                            {row.commentaireInscription ? (
                                <p className="flex directionColumn fontSmallGrey">
                                    <span>Commentaire à l'inscription:</span>
                                    <span>{row.commentaireInscription}</span>
                                </p>
                            ) : null}
                            {row.commentaire ? (
                                <p className="flex directionColumn fontSmallGrey">
                                    <span>Commentaire:</span>
                                    <span>{row.commentaire}</span>
                                </p>
                            ) : null}
                            <hr></hr>
                        </div>
                    ))}
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadScolariteInterne}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataSI, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => (
                                    <TableRow className={css.trbody} key={index}>
                                        <TableCell className={[css.td, css.w20, css.p0, css.pt1]} component="th" scope="row">
                                            <div>Année scolaire</div>
                                            {testDroit(user[usurpation].roleFonction, fonctionEleve.Supprimer_une_SI) ? (
                                                <CustomTooltips title={'Supprimer cette Scolarité.'}>
                                                    <IconButton
                                                        className={[css.p0, css.floatLeft, css.xs_none, css.ml1]}
                                                        onClick={() => {
                                                            supprimerScolarite(row.idScolarite);
                                                        }}
                                                        color="secondary"
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </CustomTooltips>
                                            ) : null}
                                            {testDroit(user[usurpation].roleFonction, fonctionEleve.Edition_SI) ? (
                                                <CustomTooltips title={'Editer cette Scolarité.'}>
                                                    <IconButton
                                                        className={[css.p0, css.floatLeft, css.mr1]}
                                                        color="primary"
                                                        onClick={() => {
                                                            listSIComplementairePossible(row.idScolarite);
                                                            props.filtre([
                                                                { AnneeScolaire: row.fk_anneeScolaire },
                                                                { Site: row.fk_site },
                                                                { Offre: row.fk_offre },
                                                                { Statut: row.fk_statut },
                                                                { Instrument: row.fk_instrument },
                                                                { Enseignant: row.fk_enseignant },
                                                                { Annee: row.fk_annee },
                                                                { Cycle: row.fk_cycle },
                                                                { StyleOffre: row.fk_style },
                                                            ]);
                                                            setCommentaire(row.commentaire);
                                                            setOpenModal(Object.assign({}, { open: true }, row));
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </CustomTooltips>
                                            ) : null}
                                            <ViewAnneeDateCreation annee={row.libelleAnneeScolaire} date={row.dateCreation} />
                                            <div className="flex directionColumn mt2 ml2">
                                                <Ateliers ateliers={row.ateliers ? row.ateliers.split('|-|') : []} />
                                            </div>
                                        </TableCell>
                                        <TableCell className={[css.td, css.w15]} component="th" scope="row">
                                            <div>Site</div>
                                            {row.libelleSite ? <span>{`Site: ${row.libelleSite}`}</span> : null}
                                            {row.nomPrenomEnseignant ? (
                                                <p className="flex directionColumn">
                                                    <span>Enseignant:</span>
                                                    <span>{row.nomPrenomEnseignant}</span>
                                                </p>
                                            ) : null}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Offre</div>
                                            <p className="flex alignICenter">
                                                <ViewLibelleOffre
                                                    complementaire={row.complementaire}
                                                    offre={row.libelleOffre}
                                                    nomStyle={row.libelleNomStyle}
                                                    style={row.libelleStyle}
                                                />
                                                <LienLivret data={row} className=" ml5" />
                                            </p>
                                            <CertificatMedical fichier={row.certificatMedical} idScolarite={row.idScolarite} />
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.precisionOffre ? <div>Précision offre</div> : null}
                                            {row.precisionOffre}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.fk_cycle ? <div>Précision FM-G</div> : null}
                                            {row.fk_cycle === null ? (
                                                ''
                                            ) : (
                                                <p className="flex directionColumn">
                                                    <span>{`Cycle ${row.fk_cycle}`}</span>
                                                    <span>{`Année ${row.fk_annee}`}</span>
                                                </p>
                                            )}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Statut</div>
                                            {row.libelleStatut}
                                        </TableCell>
                                        <TableCell className={[css.td, css.w30]} component="th" scope="row">
                                            {row.commentaireInscription ? (
                                                <p className="flex directionColumn">
                                                    <span>Commentaire à l'inscription:</span>
                                                    <span>{row.commentaireInscription}</span>
                                                </p>
                                            ) : null}
                                            {row.commentaire ? (
                                                <p className="flex directionColumn">
                                                    <span>Commentaire:</span>
                                                    <span>{row.commentaire}</span>
                                                </p>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ScolariteInterne);
