/* eslint-disable react/prop-types */
/**
 * Fichier des routes
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../Layout/layout';

/* Gestion de connexion*/
import Connexion from '../../../view/connexion';
import MotDePasseOublie from '../../../view/connexion/mot-de-passe-oublie';
import NouveauMdp from '../../../view/connexion/nouveau-mdp';
import ViewTable from '../../../view/gestionBDD/viewTable';
import ViewTableData from '../../../view/gestionBDD/viewTableData';

/* Liste */
import ListeResponsable from '../../../view/responsable/ListesResponsables';
import ListesInforamtion from '../../../view/information/ListesInformation';
import ListesGestionFrais from '../../../view/gestionFrais/ListesGestionFrais';
import ListesEleves from '../../../view/eleves/ListesEleves';
import ListeAteliers from '../../../view/atelier/ListesAteliers';
import ListeFraisAgent from '../../../view/agents/ListeFraisAgent';
import ListeTypeLivret from '../../../view/livret/ListeTypeLivret';
import ListeAgents from '../../../view/agents/ListesAgents';
import ListesAbsences from '../../../view/absence/ListesAbsences';
import ListesParametre from '../../../view/parametre/ListesParametres';
import Reglement from '../../../view/reglement/Reglement';
import Cotisation from '../../../view/cotisation/Cotisation';
import ListesPresences from '../../../view/presence/ListePresences';

/* Fiche */
import FicheEleve from '../../../view/eleves/fiche/Fiche';
import FicheResponsable from '../../../view/responsable/ficheResponsable/Fiche';
import FicheAtelier from '../../../view/atelier/fiche/Fiche';
import FicheAgent from '../../../view/agents/FicheAgent';
import FicheFrais from '../../../view/gestionFrais/FicheFrais';
import FicheLivret from '../../../view/livret/FicheLivret';
import FichePresence from '../../../view/presence/FichePresences';

/* Page d'erreur */
import PageErreur from '../../../view/erreur/PageErreur';
import Maintenance from '../../../view/screenMaintenance/maintenance';
import RetourPaybox from '../../../view/paiement/retourPaybox';

/* Autre */
import Accueil from '../../../view/accueil/Accueil';
import EditionInforamtion from '../../../view/information/EditionInformation';

/*  Gestion d'agent */
import Service from '../../../view/service/Services';
import MesCours from '../../../view/cours/MesCours';
import MesAbsences from '../../../view/absence/MesAbsences';

/*  Planning */
import PlanningSalle from '../../../view/planning/salle';

/* Espace Responsable */
import AccueilResponsable from '../../../view/espaceResponsable/accueilResponsable';
import ResponsableInformation from '../../../view/espaceResponsable/responsable/responsableInformation';
import EleveInformation from '../../../view/espaceResponsable/eleve/eleveInformation';
import ResponsableCotisation from '../../../view/espaceResponsable/responsable/responsableCotisation';
import EleveSI from '../../../view/espaceResponsable/eleve/eleveSI';
import EleveLivret from '../../../view/espaceResponsable/eleve/eleveLivret';

/* inscription */
import AccueilInscriptionTarn from '../../../view/inscription_Tarn/accueilInscription';
import ChoixParcourt from '../../../view/inscription_Tarn/choixParcours';
import ChoixOffres from '../../../view/inscription_Tarn/parcoursToutesOffres/choixOffres';
import ChoixScolarite from '../../../view/inscription_Tarn/parcoursToutesOffres/choixScolarite';
import OptionOffre from '../../../view/inscription_Tarn/optionOffre';
import FormulaireContact from '../../../view/inscription_Tarn/formulaireContact';
import InformationGeneral from '../../../view/inscription_Tarn/parcoursAgeSite/informationGeneral';
import SelectionTypeParcours from '../../../view/inscription_Tarn/parcoursAgeSite/selectionTypeParcours';
import PropositionOffre from '../../../view/inscription_Tarn/parcoursAgeSite/propositionOffre';
import FormulaireEleve from '../../../view/inscription_Tarn/formulaireEleve';
import FormulaireResponsable from '../../../view/inscription_Tarn/formulaireResponsable';
import DocumentResponsable from '../../../view/inscription_Tarn/documentResponsable';
import FinalisationInscription from '../../../view/inscription_Tarn/finalisationInscription';
import ConditionGeneral from '../../../view/inscription_Tarn/conditionGeneral';

/* gestion rôle */
import ListeGroupe from '../../../view/gestionRole/ListeGroupe';
import Groupe from '../../../view/gestionRole/Groupe';
import FicheRoles from '../../../view/gestionRole/FicheRoles/FicheRoles';

/* GED */
import Documents from '../../../view/documents/documents';

const PathURL = (props) => {
    let componentInscription = [];
    switch (process.env.REACT_APP_INSCRIPTION) {
        case 'inscription-tarn':
            componentInscription.push(<Route component={AccueilInscriptionTarn} exact path="/inscription/accueil:id?" />);
            componentInscription.push(<Route component={ChoixParcourt} exact path="/inscription/ChoixParcours/:id?" />);
            componentInscription.push(<Route component={ChoixOffres} exact path="/inscription/ChoixOffres/:id?" />);
            componentInscription.push(<Route component={ChoixScolarite} exact path="/inscription/ChoixScolarite/:id?" />);
            componentInscription.push(<Route component={OptionOffre} exact path="/inscription/OptionOffre/:id?" />);
            componentInscription.push(<Route component={FormulaireContact} exact path="/inscription/FormulaireContact" />);
            componentInscription.push(<Route component={InformationGeneral} exact path="/inscription/informationGeneral" />);
            componentInscription.push(<Route component={SelectionTypeParcours} exact path="/inscription/selectionTypeParcours" />);
            componentInscription.push(<Route component={PropositionOffre} exact path="/inscription/PropositionOffre" />);
            componentInscription.push(<Route component={FormulaireEleve} exact path="/inscription/formulaireEleve" />);
            componentInscription.push(<Route component={FormulaireResponsable} exact path="/inscription/formulaireResponsable" />);
            componentInscription.push(<Route component={DocumentResponsable} exact path="/inscription/documentResponsable" />);
            componentInscription.push(<Route component={FinalisationInscription} exact path="/inscription/finalisationInscription" />);
            componentInscription.push(<Route component={ConditionGeneral} exact path="/inscription/cgu" />);
            break;
        default:
            break;
    }
    function module() {
        const route = [];
        const modules = process.env.REACT_APP_ACCESS_MODULE.split(',');
        if (modules.includes('absences')) {
            route.push(
                <>
                    <Route component={ListesAbsences} exact path="/liste-gestion-absences" />
                    <Route component={MesAbsences} exact path="/mes-absences/:id?" />
                </>,
            );
        }
        if (modules.includes('planning')) {
            route.push(
                <>
                    <Route component={PlanningSalle} exact path="/planning-salle" />
                </>,
            );
        }
        if (modules.includes('gestionBDD')) {
            route.push(
                <>
                    <Route component={ViewTable} exact path="/viewTable" />
                    <Route component={ViewTableData} exact path="/viewTableData" />
                </>,
            );
        }
        if (modules.includes('gestionSite')) {
            route.push(
                <>
                    <Route component={ListesParametre} exact path="/liste-parametre" />
                    <Route component={ListeGroupe} exact path="/liste-groupe-role" />
                    <Route component={FicheRoles} exact path="/groupe-role/:id?" />
                </>,
            );
        }
        if (modules.includes('livret')) {
            route.push(
                <>
                    <Route component={FicheLivret} exact path="/fiche-livret/:id?/:type?" />
                    <Route component={ListeTypeLivret} exact path="/liste-livret" />
                </>,
            );
        }
        if (modules.includes('frais')) {
            route.push(
                <>
                    <Route component={ListeFraisAgent} exact path="/listeFrais-agent/:id?" />
                    <Route component={FicheFrais} exact path="/fiche-frais/:id?/:type?" />
                    <Route component={ListesGestionFrais} exact path="/liste-gestion-frais" />
                </>,
            );
        }
        if (modules.includes('services')) {
            route.push(
                <>
                    <Route component={Service} exact path="/service/:id" />
                </>,
            );
        }
        if (modules.includes('cotisation')) {
            route.push(
                <>
                    <Route component={Reglement} exact path="/liste-reglements" /> <Route component={Cotisation} exact path="/liste-cotisations" />
                </>,
            );
        }
        if (modules.includes('espaceResponsable')) {
            route.push(
                <>
                    <Route component={AccueilResponsable} exact path="/accueilResponsable" />
                    <Route component={ResponsableInformation} exact path="/responsableInformation/:id?" />
                    <Route component={ResponsableCotisation} exact path="/responsableCotisation/:id?" />
                    <Route component={EleveInformation} exact path="/eleveInformation/:id?" />
                    <Route component={EleveSI} exact path="/eleveSI/:id?" />
                    <Route component={EleveLivret} exact path="/eleveLivret/:id?" />
                </>,
            );
        }
        if (modules.includes('presences')) {
            route.push(
                <>
                    <Route component={FichePresence} exact path="/fiche-presence/:id?" />
                    <Route component={ListesPresences} exact path="/liste-presence" />
                </>,
            );
        }
        if (modules.includes('GED')) {
            route.push(
                <>
                    <Route component={Documents} exact path="/documents" />
                </>,
            );
        }
        if (modules.includes('default')) {
            route.push(
                <>
                    <Route component={ListeResponsable} exact path="/liste-responsables" />
                    <Route component={FicheResponsable} exact path="/fiche-responsable/:id?" />
                    <Route component={MesCours} exact path="/mes-cours/:id?" />
                    <Route component={EditionInforamtion} exact path="/edition-information/:id?" />
                    <Route component={ListesInforamtion} exact path="/liste-informations/:type" />
                    <Route component={ListesEleves} exact path="/liste-eleves" />
                    <Route component={FicheEleve} exact path="/fiche-eleve/:id?" />
                    <Route component={ListeAteliers} exact path="/liste-ateliers" />
                    <Route component={FicheAtelier} exact path="/fiche-atelier/:id" />
                    <Route component={FicheAgent} exact path="/fiche-agent/:id?" />
                    <Route component={ListeAgents} exact path="/liste-agents" />
                    <Route component={PageErreur} exact path="/erreur/:statut?" />
                    <Route component={Accueil} exact path="/accueil" />
                    <Route exact path="/">
                        <Redirect to="/connexion" />
                    </Route>
                </>,
            );
        }
        return route;
    }
    return (
        <Router>
            <Switch>
                <Route component={Connexion} exact path="/connexion" />
                <Route component={MotDePasseOublie} exact path="/mot-de-passe-oublie" />
                <Route component={NouveauMdp} exact path="/nouveau-mdp/:token" />
                {componentInscription}
                <Route component={Maintenance} exact path="/maintenance" />
                <Route component={RetourPaybox} exact path="/payment/:statut?" />
                <Layout nomPage={props.nomPage}>{module()}</Layout>
            </Switch>
        </Router>
    );
};

function mapStateToProps(state) {
    return { nomPage: state.NomPage };
}
export default connect(mapStateToProps, null)(PathURL);
