/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste élèves
 *
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    IconButton,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    Switch,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EuroIcon from '@material-ui/icons/Euro';

import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import Container from '../../components/atoms/Container/container';
import { GET, POST, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import ListeButton from '../../components/atoms/ButtonCustom';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHeadListeResponsable, tableHeadExport } from './const';
import * as fonctionResponsable from '../../fonctionsRoles/responsable';

const ListeResponsable = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataResponsable, setDataResponsable] = useState([]);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const filtreMultiple = useSelector((state) => state.SelectMultiple);
    const filtreSimple = useSelector((state) => state.SelectSimple);
    const [deleteRefresh, setDeleteRefresh] = useState(false);
    const history = useHistory();
    const css = useStylesMaterielUIGlobal();
    const [open, setOpen] = useState(false);
    const [inactif, setInactif] = useState(1);
    const [delIdResponsable, setDelIdResponsable] = useState();
    const [tableauFiltre, setTableauFiltre] = useState({
        AnneeScolaire: [],
        Site: [],
    });
    function Eleve(props) {
        const eleve = {
            name: props.eleve.nomPrenomEleve,
            id: props.eleve.idEleve,
            ordre: props.eleve.ordre,
        };
        const payeur = eleve.ordre === 1 ? <EuroIcon className={css.fontSize1em} /> : '';
        return (
            <Link key={props.index} to={`/fiche-eleve/${eleve.id}`}>
                <Button key={props.index}>
                    {eleve.name} {payeur}
                </Button>
            </Link>
        );
    }
    async function requeteListeResponsable() {
        const data = {
            fk_anneeScolaire: !filtreSimple.AnneeScolaire ? null : filtreSimple.AnneeScolaire,
            fk_site: filtreMultiple.Site,
            actif: inactif,
        };
        const response = await POST(`/api/utilisateursResponsables/list`, data);
        setDataResponsable(response.data);
        if (tableauFiltre.AnneeScolaire.length === 0) {
            const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
            setTableauFiltre((prevState) => ({
                ...prevState,
                AnneeScolaire: filtreAnneeScolaire.data,
            }));
            const filtreSite = await GET('/api/sites/liste');
            setTableauFiltre((prevState) => ({
                ...prevState,
                Site: filtreSite.data,
            }));
        }
    }
    async function usurpationResponsable(id, isR) {
        const response = await GET(`/api/usurpation?fk_id=${id}&isResponsable=${isR}`);
        history.push('/accueilResponsable');
        props.usurpationR(response.data);
    }
    async function suprimerResponsable(idUtilisateur) {
        await DELETE(`/api/utilisateursResponsables/${idUtilisateur}`, undefined, { redirectOnError: false });
        setDeleteRefresh(!deleteRefresh);
        setOpen(false);
    }
    function Icone(idUtilisateur) {
        const usurper = function () {
            if (testDroit(user[usurpation].roleFonction, fonctionResponsable.Usurpation_responsable)) {
                return (
                    <>
                        <CustomTooltips
                            title="Usurper le responsable"
                            children={
                                <IconButton
                                    onClick={() => {
                                        usurpationResponsable(idUtilisateur.idUtilisateur, 1);
                                    }}
                                    color="secondary"
                                    className={[css.p0, css.mr2]}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            }
                        />
                    </>
                );
            } else {
                return <></>;
            }
        };
        if (testDroit(user[usurpation].roleFonction, fonctionResponsable.Suppression_responsable)) {
            return (
                <>
                    <CustomTooltips
                        title="Supprimer le responsable"
                        children={
                            <IconButton
                                onClick={() => {
                                    setDelIdResponsable(idUtilisateur.idUtilisateur);
                                    setOpen(true);
                                }}
                                color="secondary"
                                className={[css.p0, css.mr2]}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        }
                    />
                    {usurper()}
                </>
            );
        } else {
            return <></>;
        }
    }
    useEffect(() => {
        props.addNomPage('Liste des responsables');
        if (user[usurpation].role === 'Enseignant Resp.') {
            const siteAtenne = [];

            for (let index = 0; index < user[usurpation].antennes.length; index++) {
                siteAtenne.push(user[usurpation].antennes[index].idSite);
            }
            props.filtreMultiple([{ Site: siteAtenne }]);
        }
    }, []);
    useEffect(() => {
        requeteListeResponsable();
    }, [deleteRefresh, refreshSimple, refreshMultiple, inactif]);
    return (
        <div className="listeContainer">
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        <span>Voulez-vous supprimer ce responsable ?</span>
                        <p>
                            La fiche de ce responsable sera désactivée, la réactivation est possible via la fiche de ce responsable.
                            <br />
                            Les cotisations non payées par ce responsable seront supprimées, les données de fiscalité associées à ces cotisations seront
                            supprimées.
                            <br />
                            Ce responsable sera délié de ses élèves.
                            <br />
                            Le justificatif de domicile sera supprimé.
                        </p>
                        <div>
                            <ListeButton
                                onClick={() => {
                                    setOpen(false);
                                }}
                                variant="outlined"
                                color="secondary"
                            >
                                Retour
                            </ListeButton>
                            <ListeButton
                                variant="outlined"
                                className={[css.cBleu, css.borderCbleu, css.ml2_5em]}
                                onClick={() => {
                                    suprimerResponsable(delIdResponsable);
                                }}
                            >
                                Supprimer un responsable
                            </ListeButton>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Container>
                <div className="flex flexEnd flexDrow ">
                    {testDroit(user[usurpation].roleFonction, fonctionResponsable.Ajout_nouvel_responsable) ? (
                        <Link to="/fiche-responsable/0">
                            <ListeButton variant="outlined" size="small">
                                Créer un responsable
                            </ListeButton>
                        </Link>
                    ) : null}
                    {testDroit(user[usurpation].roleFonction, fonctionResponsable.Export_excel) ? (
                        <ExportExcel data={dataResponsable} head={tableHeadExport} nomFichier="Liste Responsable" />
                    ) : null}
                </div>
                <div className="xs_flex xs_directionColumn">
                    <SelectSimple
                        tableau={tableauFiltre.AnneeScolaire}
                        indice={'AnneeScolaire'}
                        inputLabel={'Année Scolaire'}
                        classe={[css.wMin25, css.ml2]}
                        disabled={testDroit(user[usurpation].roleFonction, fonctionResponsable.Trie_annee_scolaire)}
                    ></SelectSimple>
                    <SelectMultiple
                        tableau={tableauFiltre.Site}
                        inputLabel={'Site'}
                        indice={'Site'}
                        classe={[css.wMin25, css.ml2]}
                        disabled={testDroit(user[usurpation].roleFonction, fonctionResponsable.Trie_site)}
                    ></SelectMultiple>
                </div>

                <div className="flex spaceBetween">
                    <TableRecherche className={css.w50} tableau={dataResponsable} indice={['nomPrenomUtilisateur']}></TableRecherche>
                    <div className="flex alignICenter textRight  ">
                        <label>Voir les inactifs</label>
                        <Switch checked={!+inactif} onChange={(event) => setInactif(+inactif === 0 ? 1 : 0)} />
                    </div>
                </div>
                <PaginationTable data={props.recherche.fn(dataResponsable)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadListeResponsable}></TableHeadCustom>
                        <TableBody>
                            {setData(
                                props.recherche.fn,
                                dataResponsable,
                                props.Page[0].page,
                                props.Page[0].rowsPerPage,
                                props.orderState,
                                props.orderByState,
                            ).map((row, index) => {
                                const icone = <Icone idUtilisateur={row.idUtilisateur} />;
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>
                                                <span>Responsable</span>
                                            </div>
                                            <span>{window.matchMedia('(max-width: 576px)').matches ? icone : null}</span>
                                            {window.matchMedia('(max-width: 576px)').matches ? null : icone}
                                            {testDroit(user[usurpation].roleFonction, fonctionResponsable.Lien_profil_responsable) ? (
                                                <CustomTooltips title="Éditer le responsable">
                                                    <Link to={`/fiche-responsable/${row.idUtilisateur}`}>{row.nomPrenomUtilisateur}</Link>
                                                </CustomTooltips>
                                            ) : null}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Élève(s)</div>
                                            {row.countElevesAttached}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.elevesAttached.map((elem, index) => (
                                                <Eleve key={index} eleve={elem} index={index} />
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataResponsable)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        usurpationR(user) {
            dispatch({ type: 'USURPATION_UTILISATEUR', user });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeResponsable);
