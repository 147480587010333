/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControl, Button, Select, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import TelephoneInput from '../../../components/organism/Input/telephone';
import { GET, POST, DELETE } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import DateInput from '../../../components/organism/Input/date';
import downloadFile from '../../../components/Utils/downloadFile';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import firstMajuscule from '../../../components/Utils/firstMajuscule';
import recupCommune from '../../../components/Utils/recupCommune';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import { testDroit } from '../../../components/Utils/testDroit';
import MotDePasse from '../../../components/organism/Input/motDePasse';

import { inputGeneralPart1, inputGeneralPart2 } from '../const';

import * as fonctionResponsable from '../../../fonctionsRoles/responsable';

const General = (props) => {
    let { id } = useParams();
    const history = useHistory();
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [open, setOpen] = useState(false);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [dataJustificatifDomicile, setDataJustificatifDomicile] = useState([]);
    const [Civilite, setCivilite] = useState([]);
    const [SocialCateg, setSocialCat] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [nameFile, setNameFile] = useState();
    const [dataInput, setDataInput] = useState({
        nom: '',
        prenom: '',
        email: '',
        dateNaissance: null,
        lieuNaissance: null,
        departementNaissance: null,
        adresse1: '',
        adresse2: '',
        telephoneFixe: '',
        telephoneMobile: '',
        profession: '',
        codePostal: '',
        ville: '',
        dateSuppression: null,
        actif: null,
    });
    const [uploadFileData, setUploadFileData] = useState();
    const [listeCommune, setListeCommune] = useState([]);
    const [villeOrigine, setVilleOrigine] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [erreurVille, setErreurVille] = useState(false);

    function ViewJustificatifDomicile() {
        let tableauJustificatif = <></>;
        if (dataJustificatifDomicile[0]) {
            tableauJustificatif = (
                <div className="w50 xs_w80 xs_ml5">
                    <h4>Justificatifs de domicile</h4>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {dataJustificatifDomicile.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={[css.td, css.xs_pl2_Important]} component="th" scope="row">
                                                <IconButton
                                                    onClick={() => {
                                                        test();
                                                    }}
                                                    color="secondary"
                                                    className={[css.p0, css.mr2]}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={[css.td, css.xs_pl2_Important]} component="th" scope="row">
                                                {`Année Scolaire ${row.libelleAnneeScolaire}`}
                                            </TableCell>
                                            <TableCell className={[css.td, css.xs_pl2_Important]} component="th" scope="row">
                                                <Link
                                                    onClick={() => {
                                                        downloadFile(
                                                            'justificatifDomicile',
                                                            {
                                                                fk_responsable: id,
                                                                fk_anneeScolaire: row.fk_anneeScolaire,
                                                            },
                                                            row.realFileName,
                                                        );
                                                    }}
                                                >
                                                    <span>{row.realFileName}</span>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }
        return (
            <div>
                {testDroit(user[usurpation].roleFonction, fonctionResponsable.Ajout_justificatif) ? (
                    <Button
                        size="small"
                        variant="outlined"
                        className={[css.xs_w80, css.m2]}
                        onClick={() => setOpen(true)}
                        color={props.color ? props.color : 'primary'}
                    >
                        Ajouter un justificatif de domicile
                    </Button>
                ) : null}

                {tableauJustificatif}
                <Modal content="justificatif" />
            </div>
        );
    }
    function updateState(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function Modal() {
        return (
            <DialogCustom
                open={open}
                onClose={() => setOpen(false)}
                children={
                    <div>
                        <label className="mt16px mr5">Selectionner une année scolaire</label>
                        <SelectSimple classe={[css.w80, css.mb2]} tableau={anneeScolaire} indice={'AnneeScolaire'}></SelectSimple>
                        <div className="mt2">
                            <input
                                id="justificatifDomicile-file"
                                type="file"
                                hidden
                                onChange={(event) => {
                                    uploadFile(event.target.files[0]);
                                }}
                            />
                            <div className="flex directionColumn w70">
                                <label htmlFor="justificatifDomicile-file" className="flex mb2">
                                    <Button variant="outlined" component="span">
                                        Ajouter une piece jointe
                                    </Button>
                                </label>
                                <label className="mt5 cVert32CD32">{nameFile}</label>
                            </div>
                        </div>
                        <div className="flex mt5 spaceAround">
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Annuler
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                className={[css.cBleu, css.borderCbleu, css.ml2_5em]}
                                onClick={() => {
                                    envoiJustificatifDomicile();
                                }}
                            >
                                Valider
                            </Button>
                        </div>
                    </div>
                }
            />
        );
    }
    function VilleOrigine(props) {
        if (erreurVille) {
            return (
                <div>
                    <span>
                        Le nom de la ville est mal orthographié
                        <br />
                        Merci de ressaisir la ville
                    </span>
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Ville (original)</label>
                        <Input disabled={true} value={villeOrigine} className={[css.w50, css.ml2em, css.mt0]} />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }
    async function test() {
        const data = { fk_responsable: id, fk_anneeScolaire: user.idAnneeScolaire };
        await DELETE(`/api/fichiers/remove/justificatifDomicile`, data, {
            redirectOnError: false,
        });
        recupDataResponsable();
    }
    async function uploadFile(file) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            setNameFile();
            setUploadFileData();
            return;
        }
        setNameFile(file.name);
        const data = new FormData();
        data.delete('file');
        data.delete('fk_responsable');
        data.delete('fk_anneeScolaire');
        data.append('file', file);
        data.append('fk_responsable', id);
        data.append('fk_anneeScolaire', reducerFiltre.AnneeScolaire);
        setUploadFileData(data);
    }
    async function recupFiltre() {
        const civiliteSelect = await GET('/api/utilisateursResponsables/civilites');
        setCivilite(civiliteSelect.data);
        const socialCatSelect = await GET('/api/utilisateursResponsables/categorieSocioProfessionnelle');
        setSocialCat(socialCatSelect.data);
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        setAnneeScolaire(anneeScolaireFiltre.data);
    }
    async function LoadListeCommune(codePostal) {
        const tableauCommune = await recupCommune(codePostal);
        setListeCommune(tableauCommune);
        return tableauCommune;
    }
    async function recupDataResponsable() {
        const response = await GET('/api/utilisateursResponsables/' + id);
        let commune;
        for (let index = 0; index < Object.keys(dataInput).length; index++) {
            setDataInput((prevState) => ({
                ...prevState,
                [Object.keys(dataInput)[index]]: response.data[Object.keys(dataInput)[index]],
            }));
            if (Object.keys(dataInput)[index] === 'codePostal') {
                commune = await LoadListeCommune(response.data[Object.keys(dataInput)[index]]);
            }
            if (Object.keys(dataInput)[index] === 'ville') {
                if (commune.length > 0) {
                    setVilleOrigine(response.data[Object.keys(dataInput)[index]]);
                    const bonneOrthographeCommune = commune.filter((e) => e === response.data[Object.keys(dataInput)[index]]);
                    if (bonneOrthographeCommune.length > 0) {
                        updateState(Object.keys(dataInput)[index], response.data[Object.keys(dataInput)[index]]);
                        setErreurVille(false);
                    } else {
                        setErreurVille(true);
                    }
                } else {
                    setListeCommune([response.data[Object.keys(dataInput)[index]]]);
                }
            }
        }
        props.filtre([{ SocialCateg: response.data.fk_categorieSocioProfessionnelle }, { Civilite: response.data.fk_civilite }]);
        props.addNomPage(`Profil responsable - ${response.data.nom} ${response.data.prenom}`);
        recupJustificatifDomicile();
    }
    async function recupJustificatifDomicile() {
        const justificatifsDomicile = await GET(`/api/utilisateursResponsables/${id}/justificatifs/list`);
        setDataJustificatifDomicile(justificatifsDomicile.data);
    }
    async function envoiJustificatifDomicile() {
        const response = await POST(`/api/fichiers/upload/justificatifDomicile`, uploadFileData, {
            upload: true,
            redirectOnError: false,
        });
        if (response.success === true) {
            recupJustificatifDomicile();
            setOpen(false);
        }
    }
    async function saveResponsable() {
        const data = {
            idUtilisateur: id,
            nom: dataInput.nom,
            prenom: dataInput.prenom,
            nomPrenomUtilisateur: dataInput.nom + dataInput.prenom,
            fk_civilite: reducerFiltre.Civilite === '' ? null : reducerFiltre.Civilite,
            dateNaissance: DateHeureFormat({ BDD: 'date', date: dataInput.dateNaissance }),
            lieuNaissance: dataInput.lieuNaissance,
            departementNaissance: dataInput.departementNaissance,
            adresse1: dataInput.adresse1,
            adresse2: dataInput.adresse2,
            codePostal: dataInput.codePostal,
            ville: dataInput.ville,
            telephoneFixe: dataInput.telephoneFixe,
            telephoneMobile: dataInput.telephoneMobile,
            fk_categorieSocioProfessionnelle: reducerFiltre.SocialCateg === '' ? null : reducerFiltre.SocialCateg,
            profession: dataInput.profession,
            email: dataInput.email,
            role: 'Responsable',
        };
        if (motDePasse) {
            if (!motDePasse) {
                return props.notification({ message: 'Les champs mots de passe doit être renseigné' });
            }
            data.password = motDePasse;
        }
        if (!data.nom || !data.prenom || !data.email) {
            return props.notification({
                message: 'Veuillez remplir les champs (nom, prenom, email)',
            });
        } else {
            let response;
            if (id === 0 || id === null) {
                response = await GET(`/api/utilisateursResponsables/emailExist?email=${data.email}&dateNaissance=${data.dateNaissance}`, undefined, {
                    redirectOnError: false,
                });
                if (response.success) {
                    return props.notification({
                        message: `Un compte avec cette email exite déjà.`,
                    });
                }
            }
            response = await POST('/api/utilisateursResponsables/' + id, data, { redirectOnError: false });
            if (response.success) {
                props.notification({
                    message: 'Le responsable a été enregistré',
                    status: response.success,
                });
                history.push(`/fiche-responsable/${response.data.idUtilisateur}`);
            } else {
                props.notification({
                    message: `Une erreur est survenue lors de l'enregistrement : ${response.message}`,
                });
            }
        }
    }
    async function reactivation() {
        await GET(`/api/utilisateursResponsables/${id}/ractivationresponsable`);
        recupDataResponsable();
    }
    useEffect(() => {
        recupFiltre();
    }, [reducerFiltre]);
    useEffect(() => {
        if (id !== '0') {
            recupDataResponsable();
        }
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }, []);
    return (
        <div>
            {!dataInput.dateSuppression || +dataInput.actif === 1 ? null : (
                <div className="mb2">
                    <p className="bgcRougeFF6347 cWhite textCenter fontSize1_1em">Ce compte est désactivé</p>
                    <div className="flex spaceAround w100">
                        <div className="flex alignICenter textRight mb1 w70">
                            <label>Date de désactivation</label>
                            <Input
                                disabled={true}
                                value={new Date(dataInput.dateSuppression).toLocaleString('fr-FR')}
                                className={[css.nomargin, css.ml30px, css.w30]}
                            />
                        </div>
                        <Button variant="outlined" size="small" onClick={() => reactivation()}>
                            Réactiver ce Responsable
                        </Button>
                    </div>
                </div>
            )}
            <div className="flex xs_directionColumn m_directionColumn">
                <div className="w50 xs_w100 m_w100">
                    <div className="flex alignICenter textRight mb1 ">
                        <label className="w25 xs_fontSize0_7em">Civilité</label>
                        <SelectSimple
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                            native={true}
                            tableau={Civilite}
                            indice={'Civilite'}
                            classe={[css.w500, css.ml2em, css.mt0]}
                        ></SelectSimple>
                    </div>
                    {inputGeneralPart1.map((elem, index) => (
                        <div key={index} className={`flex alignICenter textRight mb1`}>
                            <label className="w25 xs_fontSize0_7em">{firstMajuscule(elem)}</label>
                            <Input
                                disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                                value={dataInput[elem]}
                                className={[css.w50, css.ml2em, css.mt0]}
                                onChange={(event) => {
                                    updateState(elem, elem === 'nom' ? event.target.value.toUpperCase() : event.target.value);
                                }}
                            />
                        </div>
                    ))}
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">CodePostal</label>
                        <Input
                            value={dataInput.codePostal}
                            className={[css.w50, css.ml2em, css.mt0]}
                            onChange={async (event) => {
                                updateState('codePostal', event.target.value);
                                LoadListeCommune(event.target.value);
                            }}
                        />
                    </div>
                    <VilleOrigine />
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Ville</label>
                        <FormControl className={[css.w50, css.ml2em, css.mt0]}>
                            <Select
                                native={true}
                                value={dataInput.ville}
                                onChange={(event) => {
                                    updateState('ville', event.target.value);
                                }}
                            >
                                <option value={''}></option>
                                {listeCommune.map((elem, index) => {
                                    return (
                                        <option key={index} value={elem}>
                                            {elem}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Téléphone fixe</label>
                        <TelephoneInput
                            className="textLeft w50 ml30px"
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                            value={dataInput.telephoneFixe}
                            onChange={(event) => {
                                updateState('telephoneFixe', event);
                            }}
                        />
                    </div>
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Téléphone mobile</label>
                        <TelephoneInput
                            className="textLeft w50 ml30px"
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                            value={dataInput.telephoneMobile}
                            onChange={(event) => {
                                updateState('telephoneMobile', event);
                            }}
                        />
                    </div>
                </div>
                <div className="w50 xs_w100 m_w100">
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Profession</label>
                        <Input
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                            value={dataInput.profession}
                            className={[css.w50, css.ml2em, css.mt0]}
                            onChange={(event) => {
                                updateState('profession', event.target.value);
                            }}
                        />
                    </div>
                    <div className="flex alignICenter textRight mb1">
                        <label className="w25 xs_fontSize0_7em">Catégorie Socio-professionnelle</label>
                        <FormControl className={[css.w50, css.ml30px]}>
                            <SelectSimple
                                disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                                native={true}
                                tableau={SocialCateg}
                                indice={'SocialCateg'}
                                classe={css.w100}
                            ></SelectSimple>
                        </FormControl>
                    </div>
                    <DateInput
                        classesLabel="w25 xs_fontSize0_7em"
                        label={'Date de naissance'}
                        classeDiv="flex alignICenter textRight mb1"
                        disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                        format="dd/MM/yyyy"
                        value={dataInput.dateNaissance}
                        className={[css.w50, css.nonargin, css.ml30px]}
                        onChange={(event) => {
                            updateState('dateNaissance', new Date(event));
                        }}
                    />
                    {inputGeneralPart2.map((elem, index) => (
                        <div key={index} className="flex alignICenter textRight mb1">
                            <label className="w25 xs_fontSize0_7em">{elem.label}</label>
                            <Input
                                disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)}
                                value={dataInput[elem.input]}
                                className={[css.w50, css.ml2em, css.mt0]}
                                onChange={(event) => {
                                    updateState(elem.input, event.target.value);
                                }}
                            />
                        </div>
                    ))}
                    <MotDePasse value={setMotDePasse} disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable)} />
                </div>
            </div>
            {ViewJustificatifDomicile()}
            <div className="mt2 xs_flex xs_directionColumn">
                <Link to={testDroit(user[usurpation].roleFonction, fonctionResponsable.Retour_Liste_Responsable) ? '/liste-responsables' : '/accueil'}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        className={[css.xs_w90, css.m2]}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Annuler
                    </Button>
                </Link>
                {testDroit(user[usurpation].roleFonction, fonctionResponsable.Modifer_responsable) ? (
                    <Button
                        className={[css.m2, css.cBleu, css.xs_w90, css.borderCbleu]}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            saveResponsable();
                        }}
                    >
                        valider
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(General);
