export const Liste_des_absences = 201;
export const Liste_des_frais = 204;
export const Liste_des_informations = 207;
export const Liste_des_model_de_livrets = 210;
export const Cotisation_réglement = 214;
export const Etat_des_réglements = 219;
export const Liste_responsables = 90;
export const Liste_élèves = 112;
export const Liste_Agent = 137;
export const Liste_Atelier = 178;
export const Salle = 221;
export const Paramétre = 225;
export const Gestion_BDD = 1;
export const Gestion_de_rôle = 232;
export const Model_inscription = 226;
export const Mes_services = 252;
export const Mes_absences = 176;
export const Mes_frais = 175;
export const Mes_cours = 261;
export const Mes_profil = 245;
export const Liste_presences = 271;
export const GED = 290;
export const Liste_des_informations_CST = 295;
