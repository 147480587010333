/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox } from '@material-ui/core';

import { POST, GET } from '../../components/Utils/requete';
import DialogCustom from '../../components/organism/Dialog/dialog';
import Wait from '../../components/atoms/Wait';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ConditionGeneral = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [CGU, setCGU] = useState(false);
    const [messageErreur, setMessageErreur] = useState();
    const [open, setOpen] = useState(false);
    const [wait, setWait] = useState(false);
    const [textModal, setTextModal] = useState('Votre inscription est terminée, vous allez étre redirigé vers la page de connexion de votre espace.');
    const history = useHistory();
    const data = useSelector((state) => state.DataInscriptionTarn);

    function Modal() {
        return (
            <DialogCustom
                open={open}
                onClose={() => {
                    history.push('/connexion');
                }}
                children={
                    <div className="nomargin flex alignICenter w100">
                        <p>{textModal}</p>
                        <Button
                            size="small"
                            variant="contained"
                            className={[css.BcBleu, css.radius10, css.cWhite, css.ml15]}
                            onClick={() => history.push('/connexion')}
                        >
                            OK
                        </Button>
                    </div>
                }
            />
        );
    }

    async function saveInscription() {
        if (!CGU) {
            setWait(false);
            setMessageErreur('Vous devez valider les conditions avant de pouvoir continuer');
        } else {
            let response;
            setMessageErreur();
            response = await POST('/api/cotisations/cotisationsInscriptionSauvegarde', data);

            const dataResponse = response;
            const params = {
                upload: true,
                redirectOnError: false,
                token: dataResponse.data.token,
                idUtilisateur: dataResponse.data.idUtilisateur,
            };

            if (dataResponse.data === 'Ce compte existe déjà') {
                setWait(false);
                setTextModal('Un compte avec cette adresse mail exite déjà, vous devez vous inscrire via votre espace responsable.');
                setOpen(true);
            }
            if (dataResponse.success) {
                if (data.document.fiscalite.document) {
                    const file = data.document.fiscalite.document;
                    file.delete('fk_responsable');
                    file.delete('fk_anneeScolaire');
                    file.append('fk_responsable', dataResponse.data.fk_id);
                    file.append('fk_anneeScolaire', data.anneeScolaire);
                    response = await POST('/api/fichiers/upload/responsablesRevenus', file, params);
                }
                if (data.document.justificatifDomicile) {
                    const file = data.document.justificatifDomicile;
                    file.delete('fk_responsable');
                    file.delete('fk_anneeScolaire');
                    file.append('fk_responsable', dataResponse.data.fk_id);
                    file.append('fk_anneeScolaire', data.anneeScolaire);
                    response = await POST('/api/fichiers/upload/justificatifDomicile', file, params);
                }
                setOpen(true);
                setWait(false);
            }
        }
    }
    return (
        <div>
            {wait ? <Wait /> : null}
            <div div className="bgcfff h100 flex directionColumn p2">
                {Modal()}
                <div className="flex alignICenter w100">
                    <div className="pl2 w55 radius10" style={{ border: `2px solid #2e76b0 ` }}>
                        <h3>Règlement du conservatoire</h3>
                    </div>
                    <Button
                        size="small"
                        variant="contained"
                        className={[css.BcBleu, css.radius10, css.cWhite, css.ml15]}
                        onClick={() => {
                            /* setWait(true); */
                            saveInscription();
                        }}
                    >
                        Finaliser l'inscription
                    </Button>
                </div>
                <div className="p2">
                    <p className="floatRight cRed">{messageErreur}</p>
                    <ol>
                        <li>SUIVI PÉDAGOGIQUE</li>
                        <p>
                            Je m’engage à suivre le travail de mon enfant. Pour cela je prendrai régulièrement contact avec ses professeurs qui m’informeront de
                            ses progrès notamment grâce aux évaluations consultables sur l’espace élèves du site cmdtarn.fr. J’accepte les dispositions du
                            règlement pédagogique ; l’inscription au Conservatoire comprend la participation obligatoire aux activités prévues par
                            l’établissement dont le planning sera communiqué en cours d’année. Au cas où mon enfant ne pourrait suivre normalement son (ses)
                            cours, je ferai mon possible pour avertir son (ses) professeur(s).
                        </p>
                        <li>DROIT A L’IMAGE</li>
                        <p>
                            J’autorise le Conservatoire à photographier, enregistrer, filmer mon enfant lors des spectacles et à utiliser photos, vidéo,
                            enregistrements sans contrepartie financière aux fins de démonstration, information, promotion des réalisations sur tous les
                            supports de communication du Conservatoire. Je m’engage à accepter l’enregistrement de ses prestations en vue d’éventuelles
                            publications télévisuelles ou radiophoniques sans contrepartie financière et à autoriser le Conservatoire à les utiliser à des fins
                            non commerciales. En cas de refus, le signaler par email à secretariat.direction@cmdtarn.fr
                        </p>
                        <li>SÉCURITÉ</li>
                        <p>
                            Je dégage le Conservatoire de toute responsabilité envers mon enfant en dehors de ses cours. J’ai bien pris note que mon enfant
                            n’est sous la responsabilité du Conservatoire qu’à partir du moment où il est confié au professeur dans sa classe et uniquement pour
                            la durée du cours. (Les parents doivent s’assurer de la présence du professeur). En cas de nécessité, j’autorise l’hospitalisation
                            de mon enfant.
                        </p>
                        <li>PAIEMENT</li>
                        <p>TOUTE ANNÉE COMMENCÉE EST ENTIEREMENT DUE.</p>
                        <p>
                            PÉRIODE de règlement 2022/23 : du 1er septembre au 30 novembre 2022 en 1, 2 ou 3 fois. Seul le tarif unique de 80€ sera à régler
                            obligatoirement en une seule fois. Après le 30 novembre, votre dossier sera transmis au service contentieux du Trésor Public, ce qui
                            pourra générer une majoration de vos droits d'inscription.
                        </p>
                    </ol>
                    <Checkbox
                        checked={CGU}
                        onChange={() => {
                            setCGU(!CGU);
                        }}
                    />
                    <label>
                        Je certifie l’exactitude des renseignements ci-dessus et je suis conscient(e) qu’une fausse déclaration (notamment de domicile) est une
                        infraction pénale.
                    </label>
                </div>
            </div>
        </div>
    );
};

export default connect(null, null)(ConditionGeneral);
