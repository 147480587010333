/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControl, Checkbox, Button, IconButton, Select, InputLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import { GET, POST, DELETE } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';
import creationTableauFiltre from '../../../components/Utils/createTableauFiltre';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import * as fonctionEleve from '../../../fonctionsRoles/eleve';

const tableHeadScolariteExterne = [
    { id: 'AnneesScolaireExterne', label: 'Année scolaire' },
    { id: 'etablissementExterne', label: 'Etablissement' },
    { id: 'niveauExterne', label: 'Niveau' },
    { id: '', label: 'Cours Mercredi' },
];

const ScolariteExterne = (props) => {
    let { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const refresh = useSelector((state) => state.SelectSimple.refreshSimple);
    const css = useStylesMaterielUIGlobal();
    const [courMercredi, setCourMercredi] = useState(false);
    const [open, setOpen] = useState(false);
    const [listeSE, setListeSE] = useState([]);
    const [idScolariteUpdate, setIdScolariteUpdate] = useState();
    const [commune, setCommune] = useState('');
    const [tableauFiltre, setTableauFiltre] = useState({
        AnneeScolaire: [],
        Niveau: [],
        Commune: [],
        Etablissement: [],
    });

    function updateState(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function notification(note) {
        props.notification({ message: note.message, status: note.status });
    }

    function Modal() {
        return (
            <DialogCustom
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                children={
                    <>
                        <SelectSimple
                            tableau={tableauFiltre.AnneeScolaire}
                            indice={'AnneeScolaire'}
                            inputLabel={'Année Scolaire'}
                            classe={css.w100}
                        ></SelectSimple>
                        <SelectSimple tableau={tableauFiltre.Niveau} indice={'Niveau'} inputLabel={'Niveau'} classe={css.w100}></SelectSimple>
                        <FormControl className={css.w100}>
                            <InputLabel>Ville</InputLabel>
                            <Select native={true} onChange={(event) => requeteEtablissements(event.target.value)} value={commune}>
                                <option value={''}></option>
                                {tableauFiltre.Commune.map((elem, index) => {
                                    return (
                                        <option key={index} value={elem.commune}>
                                            {elem.commune}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <SelectSimple
                            tableau={tableauFiltre.Etablissement}
                            indice={'Etablissement'}
                            inputLabel={'Etablissement'}
                            classe={css.w100}
                        ></SelectSimple>
                        <div>
                            <Checkbox
                                checked={courMercredi}
                                onChange={() => {
                                    setCourMercredi(!courMercredi);
                                }}
                            />
                            <span>Cour mercredi matin</span>
                        </div>
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => {
                                saveScolariteExterne(idScolariteUpdate);
                            }}
                        >
                            Valider
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            className={css.ml2}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            annuler
                        </Button>
                    </>
                }
            />
        );
    }

    async function dataSE() {
        const response = await GET(`/api/eleves/${id}/SE_list`);
        setListeSE(response.data);
        if (tableauFiltre.AnneeScolaire.length === 0) {
            let response = await GET('/api/anneesScolaire/list');
            updateState('AnneeScolaire', response.data);
            response = await GET('/api/eleves/SE_niveaux');
            updateState('Niveau', response.data);
            response = await GET('/api/eleves/SE_etablissements?mode=communes');
            updateState('Commune', response.data);
        }
    }

    async function requeteEtablissements(commune) {
        setCommune(commune);
        const etablissementsFiltre = await GET(`/api/eleves/SE_etablissements?mode=etablissements&commune=${commune}`, undefined, {
            redirectOnError: false,
        });
        updateState('Etablissement', creationTableauFiltre(etablissementsFiltre.data, 'idEtablissement', 'libelleEtablissement'));
    }

    async function saveScolariteExterne(idSE) {
        const data = {
            idScolarite: idScolariteUpdate ? idScolariteUpdate : 0,
            fk_eleve: id,
            fk_anneeScolaire: reducerFiltre.AnneeScolaire,
            fk_etablissementScolaire: reducerFiltre.Etablissement,
            fk_niveauScolaire: reducerFiltre.Niveau,
            coursMercrediMatin: courMercredi === true ? 1 : 0,
        };
        if (reducerFiltre.AnneeScolaire) {
            const response = await POST('/api/eleves/' + id + '/scolaritesExterne/' + data.idScolarite, data);
            if (response.success === false) {
                notification({ message: 'Veuillez remplir tous les champs' });
            } else {
                dataSE('dataScolariteExterne');
                setOpen(false);
            }
        } else {
            notification({ message: 'Veuillez remplir tous les champs' });
        }
        setCourMercredi(false);
        setIdScolariteUpdate(undefined);
    }

    async function updateScolariteExterne(SE) {
        props.filtre([{ Niveau: SE.fk_niveauScolaire }, { Etablissement: SE.fk_etablissementScolaire }, { AnneeScolaire: SE.fk_anneeScolaire }]);
        setIdScolariteUpdate(SE.idScolarite);
        if (SE.coursMercrediMatin === 1) {
            setCourMercredi(true);
        }
        setOpen(true);
    }

    async function supprimerScolarite(idScolarite) {
        await DELETE(`/api/eleves/${id}/scolaritesExterne/${idScolarite}`, undefined, { redirectOnError: false });
        dataSE();
    }

    useEffect(() => {
        dataSE();
    }, [refresh]);

    return (
        <div>
            {Modal()}
            <h3 className="none xs_block textCenter mt5">Scolarité Education Nationale</h3>
            {testDroit(user[usurpation].roleFonction, fonctionEleve.Ajout_nouvelle_SE) ? (
                <Button
                    variant="outlined"
                    size="small"
                    className={[css.ml2, css.mb2]}
                    onClick={() => {
                        setOpen(true);
                        props.removeFiltre();
                        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
                        setCommune('');
                    }}
                >
                    Ajouter une scolarité externe
                </Button>
            ) : null}

            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadScolariteExterne}></TableHeadCustom>
                    <TableBody>
                        {listeSE.map((row, index) => {
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Année scolaire</div>
                                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Supprimer_une_SE) ? (
                                            <CustomTooltips title={'Supprimer cette Scolarité.'}>
                                                <IconButton
                                                    className={[css.p0, css.floatRight]}
                                                    onClick={() => {
                                                        supprimerScolarite(row.idScolarite, 'dataScolariteExterne');
                                                    }}
                                                    color="secondary"
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </CustomTooltips>
                                        ) : null}
                                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Edition_SE) ? (
                                            <CustomTooltips title={'Editer cette Scolarité.'}>
                                                <IconButton
                                                    className={[css.p0, css.floatRight]}
                                                    onClick={() => {
                                                        requeteEtablissements(row.commune);
                                                        updateScolariteExterne(row);
                                                    }}
                                                    color="primary"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </CustomTooltips>
                                        ) : null}

                                        {row.libelleAnneeScolaire}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Etablissement</div>
                                        {row.commune ? (
                                            <span className="flex alignICenter nomargin">
                                                <LocationOnIcon />
                                                {` ${row.commune}`}
                                            </span>
                                        ) : null}
                                        {row.libelleEtablissement ? (
                                            <span className="flex alignICenter nomargin">
                                                <LocationCityIcon />
                                                {` ${row.libelleEtablissement}`}
                                            </span>
                                        ) : null}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Niveau</div>
                                        {row.libelleNiveauScolaire}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Cour mercredi</div>
                                        {row.coursMercrediMatin === 1 ? 'oui' : 'non'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ScolariteExterne);
