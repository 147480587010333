/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FormControl, Button, Checkbox, Select, FormHelperText } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import Input from '../../components/atoms/TextField';
import TelephoneInput from '../../components/organism/Input/telephone';
import DateInput from '../../components/organism/Input/date';
import valuesQuery from '../../components/Utils/valuesQuery';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const FormulaireEleve = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const dataEleve = useSelector((state) => state.DataInscriptionTarn.eleves[valuesQuery('numeroEleve') - 1]);
    const data = useSelector((state) => state.DataInscriptionTarn);
    const reloadView = useSelector((state) => state.DataInscriptionTarn.reloadView);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataFiltre, setDataFiltre] = useState({
        communes: [],
        etablissement: [],
        niveau: [],
    });
    const [affichageChoixScolaire, setAffichageChoixScolaire] = useState(false);
    const [checkecedCourMercredi, setCheckecedCourMercredi] = useState(false);
    const [nonScolarise, setNonScolarise] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEducationNationale, setErrorEducationNationale] = useState();
    const verifData = [dataEleve.fk_genre, dataEleve.nom, dataEleve.prenom, dataEleve.dateNaissance /* , dataEleve.telephoneMobile */];
    const [scolariteExterne, setScolariteExterne] = useState(false);
    const [libelleAnnee, setLibelleAnne] = useState();
    async function inscriptionOuverte() {
        if (!libelleAnnee) {
            const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');

            const libelle = filtreAnneeScolaire.data.filter((e) => e.idAnneeScolaire === data.anneeScolaire);
            setLibelleAnne(libelle[0].libelleAnneeScolaire);
        }
    }
    async function requeteSitesEtablissements() {
        const siteFiltre = await GET('/api/eleves/SE_etablissements?mode=communes', undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            communes: creationTableauFiltre(siteFiltre.data, '', 'commune'),
        }));
        const niveauFiltre = await GET('/api/eleves/SE_niveaux', undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            niveau: creationTableauFiltre(niveauFiltre.data, 'idNiveau', 'libelle'),
        }));
    }
    async function requeteEtablissements(commune) {
        let communes = commune;
        if (commune === 'Autre') {
            communes = ` Autre`;
        }
        const etablissementsFiltre = await GET(`/api/eleves/SE_etablissements?mode=etablissements&commune=${communes}`, undefined, {
            redirectOnError: false,
        });
        props.scolariteExterneData({
            listeEtablissement: creationTableauFiltre(etablissementsFiltre.data, 'idEtablissement', 'libelleEtablissement'),
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            etablissement: creationTableauFiltre(etablissementsFiltre.data, 'idEtablissement', 'libelleEtablissement'),
        }));
    }
    async function savescolariteInterne() {
        data.idResponsable = user[usurpation].fk_id;
        await POST('/api/cotisations/cotisationsInscriptionSauvegarde', data);
    }
    function ajoutCommune(event, reducer) {
        props.scolariteExterneData({ [reducer]: event });
        if (reducer === 'fk_niveauScolaire') {
            setScolariteExterne(true);
        }
        if (reducer === 'communes') {
            requeteEtablissements(event);
        }
    }
    if (dataFiltre.communes.length === 0) {
        requeteSitesEtablissements();
    }
    function infoEducNat() {
        if (dataEleve.dateNaissance) {
            if (
                Math.abs(
                    new Date(
                        new Date(new Date().setMonth(11)).setDate(31) - new Date(dataEleve.dateNaissance.split('/').reverse().join('-')).getTime(),
                    ).getFullYear() - 1970,
                ) <= 25
            ) {
                if (!dataEleve.scolaritesExterne) {
                    props.ajoutScolariteExterne();
                }
                inscriptionOuverte();
                const select = (
                    <div className="p2 border1darkgrey radius10">
                        <h4 className="textCenter nomargin mb2">Scolarité éducation nationale</h4>
                        <div className="flex alignICenter justifyContantEnd mr5">
                            <Checkbox
                                value={dataEleve.scolaritesExterne.nonScolarise}
                                checked={nonScolarise}
                                onChange={() => {
                                    setScolariteExterne(true);
                                    setNonScolarise(!nonScolarise);
                                    props.scolariteExterneData({ nonScolarise: !nonScolarise });
                                }}
                            />
                            <label>Non scolarisé</label>
                        </div>
                        {Object.keys(dataFiltre).map((e, i) => {
                            let label = '';
                            switch (e) {
                                case 'communes':
                                    label = { label: 'Ville', state: 'communes', reducer: 'communes' };
                                    break;
                                case 'etablissement':
                                    label = {
                                        label: 'Établissement',
                                        state: 'etablissement',
                                        reducer: 'fk_etablissementScolaire',
                                    };
                                    break;
                                case 'niveau':
                                    label = {
                                        label: `Niveau scolaire ${libelleAnnee}`,
                                        state: 'niveau',
                                        reducer: 'fk_niveauScolaire',
                                    };
                                    break;
                                default:
                                    break;
                            }
                            return (
                                <div className="mb5" key={i}>
                                    <span>{label.label} :</span>
                                    <FormControl className={[css.w100]}>
                                        <Select
                                            value={dataEleve.scolaritesExterne[label.reducer]}
                                            native={true}
                                            onChange={(event) => ajoutCommune(event.target.value, label.reducer)}
                                            disabled={nonScolarise}
                                        >
                                            <option value={''}></option>
                                            {dataFiltre[e].map((elem, index) => {
                                                return (
                                                    <option key={index} value={elem.id}>
                                                        {elem.libelle}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        })}
                        <div className="flex alignICenter">
                            {courMercrediChecked()}
                            <label>Ecole mercredi matin</label>
                        </div>
                    </div>
                );
                return select;
            }
        }
        if (!scolariteExterne) {
            setScolariteExterne(true);
        }
        return <></>;
    }
    function date() {
        return (
            <DateInput
                label="Date de naissance"
                format="dd/MM/yyyy"
                classesLabel="w25 "
                maxDate={new Date()}
                classeDiv="flex alignICenter "
                className={[css.w60, css.nonargin]}
                onChange={(event) => {
                    props.dataEleve({ dateNaissance: event ? event.toLocaleDateString('fr-FR') : event });
                    setAffichageChoixScolaire(!affichageChoixScolaire);
                    setErrorEducationNationale();
                }}
                value={dataEleve.dateNaissance ? new Date(dataEleve.dateNaissance.split('/').reverse().join('-')) : null}
                helperText={<span style={{ color: 'red' }}>{errorMessage[3]}</span>}
            />
        );
    }
    function courMercrediChecked() {
        if (dataEleve.scolaritesExterne) {
            return (
                <Checkbox
                    value={dataEleve.scolaritesExterne.coursMercrediMatin}
                    disabled={nonScolarise}
                    checked={dataEleve.scolaritesExterne.coursMercrediMatin}
                    onChange={() => {
                        props.scolariteExterneData({
                            coursMercrediMatin: !dataEleve.scolaritesExterne.coursMercrediMatin ? 1 : 0,
                        });
                        setCheckecedCourMercredi(!checkecedCourMercredi);
                    }}
                />
            );
        } else {
            return <></>;
        }
    }
    function champNonRemplis() {
        const erreur = [];
        for (let index = 0; index < verifData.length; index++) {
            if (!verifData[index]) {
                erreur.push('information manquante');
            } else {
                erreur.push('');
            }
        }
        setErrorMessage(erreur);
    }
    function dataIsSet(url) {
        if (verifData.includes(null) || verifData.includes('')) {
            champNonRemplis();
            setErrorEducationNationale();
        } else if (!scolariteExterne) {
            champNonRemplis();
            setErrorEducationNationale('Ce cadre doit être renseigné');
        } else {
            if (url === '/accueilResponsable') {
                savescolariteInterne();
            }
            history.push(url);
        }
    }
    function Bouton() {
        if (valuesQuery('idEleve') === 'null') {
            return (
                <>
                    <Button
                        size="small"
                        variant="contained"
                        className={[css.BcBleu, css.radius10, css.cWhite, css.mb5, css.ml15]}
                        onClick={() => dataIsSet('/accueilResponsable')}
                    >
                        Finaliser cette offre
                    </Button>
                </>
            );
        }
        if (!valuesQuery('reinscription')) {
            return (
                <Button
                    size="small"
                    onClick={() => dataIsSet(valuesQuery('finalisation') ? '/inscription/finalisationInscription' : '/inscription/formulaireResponsable')}
                    variant="contained"
                    className={[css.BcBleu, css.radius10, css.cWhite, css.mb5, css.ml15]}
                >
                    Continuer l'inscription
                </Button>
            );
        }

        return <></>;
    }
    function view() {
        return (
            <div className=" bgcfff h100 flex directionColumn p3">
                <div className="flex alignICenter w100">
                    <div className="flex  pl2 w55 radius10 directionColumn" style={{ border: `2px solid #2e76b0` }}>
                        <h3>Renseignements concernant l’élève</h3>
                    </div>
                </div>
                <div className="p5 ">
                    <div className="flex mt2  spaceAround">
                        <div className="w50  ml2">
                            <div className="flex mb5">
                                <label className="w25">Genre</label>
                                <FormControl className={[css.w60]} error={errorMessage[0]}>
                                    <Select
                                        value={dataEleve.fk_genre}
                                        className={css.w100}
                                        onChange={(event) => props.dataEleve({ fk_genre: event.target.value })}
                                    >
                                        <option value=""></option>
                                        <option value={1}>Féminin</option>
                                        <option value={2}>Masculin</option>
                                    </Select>
                                    <FormHelperText>{errorMessage[0]}</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="flex">
                                <label className="w25 ">Nom</label>
                                <Input
                                    value={dataEleve.nom}
                                    className={[css.w60, css.mt0]}
                                    onChange={(event) => props.dataEleve({ nom: event.target.value.toUpperCase() })}
                                    helperText={<span style={{ color: 'red' }}>{errorMessage[1]}</span>}
                                />
                            </div>
                            <div className="flex">
                                <label className="w25 ">Prénom</label>
                                <Input
                                    value={dataEleve.prenom}
                                    className={[css.w60, css.mt0]}
                                    onChange={(event) => props.dataEleve({ prenom: event.target.value })}
                                    helperText={<span style={{ color: 'red' }}>{errorMessage[2]}</span>}
                                />
                            </div>
                            {date()}
                            <div className="flex mt5">
                                <div className="flex directionColumn w35">
                                    <label>Téléphone mobile</label>
                                    <label className="fontVerySmallGrey ">Le numéro mobile de l’élève n'est pas obligatoire</label>
                                </div>
                                <TelephoneInput
                                    value={dataEleve.telephoneMobile}
                                    className="textLeft "
                                    onChange={(event) => props.dataEleve({ telephoneMobile: event })}
                                    errorMessage={errorMessage[4]}
                                />
                            </div>
                        </div>
                        <div className="w30 ">
                            {infoEducNat()}
                            <span style={{ color: 'red' }}>{errorEducationNationale}</span>
                        </div>
                    </div>
                </div>
                <div className="flex ">
                    <Bouton />
                </div>
            </div>
        );
    }
    useEffect(() => {
        if (!dataEleve) {
            history.push('/inscription/accueil');
        }
    }, []);
    useEffect(() => {
        if (valuesQuery('finalisation') && dataEleve.scolaritesExterne) {
            setDataFiltre((prevState) => ({
                ...prevState,
                etablissement: dataEleve.scolaritesExterne.listeEtablissement,
            }));
            setScolariteExterne(true);
        }
        view();
    }, [reloadView]);
    useEffect(() => {
        date();
    }, [affichageChoixScolaire]);
    useEffect(() => {
        courMercrediChecked();
    }, [checkecedCourMercredi]);
    return view();
};

function mapDispatchToProps(dispatch) {
    return {
        ajoutEleve(numeroEleve) {
            dispatch({ type: 'ajoutEleve' });
        },
        dataEleve(data) {
            dispatch({ type: 'dataEleve', data: data, numeroEleve: valuesQuery('numeroEleve') });
        },
        ajoutScolariteExterne() {
            dispatch({ type: 'scolariteExterne', numeroEleve: valuesQuery('numeroEleve') });
        },
        scolariteExterneData(data) {
            dispatch({ type: 'scolariteExterneData', data: data, numeroEleve: valuesQuery('numeroEleve') });
        },
    };
}

export default connect(null, mapDispatchToProps)(FormulaireEleve);
