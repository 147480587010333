/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

import { DELETE, GET, POST } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import setData from '../../../components/organism/Table/setDataTableau';
import PaginationTable from '../../../components/organism/Table/Pagination';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import { testDroit } from '../../../components/Utils/testDroit';
import { ButtonCustom } from './Fiche';
import SelectInstrument from '../../../components/organism/Instrument&Affectation/select';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadEnseignantF, tableHeadAjoutEnseignant } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

const AtelierEnseignants = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const valueFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    let { id } = useParams();
    const [dataEnseignant, setDataEnseignant] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [dataRechercheEnseignant, setDataRechercheEnseignant] = useState([]);
    const [instrument, setInstrument] = useState();
    function Modal() {
        let content;
        let fullScreen = false;
        switch (open.event) {
            case 'supprEnseignant':
                content = (
                    <>
                        <span className="mr2 textCenter w100 block">Voulez-vous supprimer cet enseignant</span>
                        <div className="mt2 textCenter">
                            <ButtonCustom
                                type="bouton"
                                label="Non"
                                onClick={() => setOpen({ open: false })}
                                color="secondary"
                                className={[css.mr20, css.mt2]}
                            />
                            <ButtonCustom
                                type="bouton"
                                label="Oui"
                                onClick={() => {
                                    suppressionEnseignant(open.params);
                                }}
                                suppr="supprEnseignant"
                            />
                        </div>
                    </>
                );
                break;
            case 'addEnseignant':
                fullScreen = true;
                content = (
                    <>
                        <ButtonCustom type="bouton" label="Retour" onClick={() => setOpen({ open: false })} color="secondary" />
                        <h3>Recherche d'un enseignant</h3>
                        <PaginationTable data={dataRechercheEnseignant} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                        <div className="mb10px">
                            <SelectInstrument enseignement="1" anneeEnCour={user[usurpation].idAnneeScolaire} value={valueAffectation} />
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAjoutEnseignant}></TableHeadCustom>
                                <TableBody>
                                    {setData(props.recherche.fn, dataRechercheEnseignant, props.Page[0].page, props.Page[0].rowsPerPage).map((row, index) => {
                                        const button = (
                                            <ButtonCustom
                                                type="icone"
                                                label={<AddBoxIcon />}
                                                className={[css.cVert, css.p0, css.mr2]}
                                                onClick={() => {
                                                    associerEnseignant(row.idUtilisateur, row.nomPrenomUtilisateur);
                                                }}
                                            />
                                        );
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>
                                                        Enseignant
                                                        {window.matchMedia('(max-width: 576px)').matches ? button : null}
                                                    </div>
                                                    {window.matchMedia('(min-width: 576px)').matches ? button : null}
                                                    {row.nomPrenomUtilisateur}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Fixe</div>
                                                    {row.telephoneFixe}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Mobile</div>
                                                    {row.telephoneMobile}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                );
                break;
            case 'editionDiscipline':
                content = (
                    <>
                        <span className="mr2 textCenter w100 block">Modifier la discipline</span>
                        <div className="mb10px">
                            <SelectInstrument enseignement="1" anneeEnCour={user[usurpation].idAnneeScolaire} value={valueAffectation} />
                        </div>
                        <div className="mt2 textCenter">
                            <ButtonCustom
                                type="bouton"
                                label="Retour"
                                onClick={() => setOpen({ open: false })}
                                color="secondary"
                                className={[css.mr20, css.mt2]}
                            />
                            <ButtonCustom
                                type="bouton"
                                label="Valider"
                                onClick={() => {
                                    associerEnseignant(open.params);
                                }}
                            />
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
        return (
            <DialogCustom
                open={open.open}
                onClose={() => {
                    setOpen({ open: false, content: '' });
                }}
                children={content}
                fullScreen={fullScreen}
            />
        );
    }

    function valueAffectation(value) {
        setInstrument(value);
    }

    async function recupDataAtelier(duplicateID) {
        const responseEnseignant = await POST('/api/ateliers/enseignantsByAteliers', {
            fk_atelier: props.id,
        });
        setDataEnseignant(responseEnseignant.data);
    }
    async function recupDataRechercheEnseignant() {
        const data = {
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            fk_site: valueFiltre.Site,
        };
        const response = await POST('/api/utilisateursAdministratifs/listEnseignantsFromSiteAndAnneeScolaire', data);
        setDataRechercheEnseignant(response.data);
    }

    async function associerEnseignant(idUser, nomUser) {
        if (!instrument) return props.notification({ message: `Vous devez affecter une discipline à l'enseignant` });
        await GET(`/api/ateliers/${id}/attachedEnseignant/${idUser}?idInstrument=${instrument}`);
        if (nomUser) props.notification({ message: `Mme\\Mr ${nomUser} a été ajouté à l'atelier`, status: true });
        setOpen({ open: false });
        setInstrument();
        return recupDataAtelier();
    }
    async function suppressionEnseignant(idUser) {
        await DELETE(`/api/ateliers/${id}/attachedEnseignant/${idUser}`);
        setOpen({ open: false });
        return recupDataAtelier();
    }
    useEffect(() => {
        recupDataAtelier();
    }, []);

    return (
        <div>
            {Modal()}
            <div className="flex">
                <h3 className="w100 nomargin">Enseignants</h3>
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_enseignant) ? (
                    <ButtonCustom
                        disabled={+id === 0}
                        type="icone"
                        label={<AddBoxIcon />}
                        className={[css.cVert, css.xs_mr5, css.p0]}
                        onClick={() => {
                            recupDataRechercheEnseignant();
                            setOpen({ open: true, event: 'addEnseignant' });
                        }}
                    />
                ) : null}
            </div>
            <hr></hr>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadEnseignantF}></TableHeadCustom>
                    <TableBody>
                        {dataEnseignant.map((row, index) => {
                            const button = testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_enseignant) ? (
                                <ButtonCustom
                                    type="icone"
                                    label={<DeleteForeverIcon />}
                                    color="secondary"
                                    onClick={() => {
                                        setOpen({
                                            open: true,
                                            event: 'supprEnseignant',
                                            params: row.fk_enseignant,
                                        });
                                    }}
                                    className={[css.p0, css.floatRight]}
                                />
                            ) : null;
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>
                                            Enseignants
                                            {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_enseignant)
                                                ? window.matchMedia('(max-width: 576px)').matches
                                                    ? button
                                                    : null
                                                : null}
                                        </div>
                                        {window.matchMedia('(min-width: 576px)').matches ? button : null}
                                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_enseignant) ? (
                                            <ButtonCustom
                                                type="icone"
                                                label={<EditIcon />}
                                                onClick={() => {
                                                    setOpen({
                                                        open: true,
                                                        event: 'editionDiscipline',
                                                        params: row.fk_enseignant,
                                                    });
                                                }}
                                                className={[css.p0, css.floatRight]}
                                            />
                                        ) : null}
                                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant) ? (
                                            <Link to={`/mes-cours/${row.fk_enseignant}`}>{row.nomPrenomUtilisateur}</Link>
                                        ) : (
                                            row.nomPrenomUtilisateur
                                        )}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Discipline</div>
                                        {row.libelleInstrument}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AtelierEnseignants);
