/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import { StatutFrais } from '../../components/Utils/consts';
import Input from '../../components/atoms/TextField';
import JourFrais from '../../components/organism/Frais/jourFrais';
import euroFormate from '../../components/Utils/euroFormate';
import dateSave from '../../components/Utils/dateSave';
import DateInput from '../../components/organism/Input/date';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionFrais from '../../fonctionsRoles/frais';

const Frais = (props) => {
    const history = useHistory();
    const css = useStylesMaterielUIGlobal();
    const { id } = useParams();
    const { type } = useParams();
    const [elemJour, setElemJour] = useState([]);
    const [delT, setDelT] = useState(false);
    const [gestion, setGestion] = useState(type === 'gestion' ? true : false);
    const infoJour = useSelector((state) => state.FraisJour);
    const refreshJour = useSelector((state) => state.FraisJour.refreshJour);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const date = new Date();
    const [idUser, setIdUser] = useState();
    const [nomAgent, setNomAgent] = useState();
    const [brouillon, setBrouillon] = useState(false);
    const [fraisReculPeriode, setFraisReculPeriode] = useState(0);
    const [vehicule, setVehicule] = useState({ vehiculeMarque: '', vehiculeModele: '', vehiculePuissance: '' });
    const [dataInput, setDataInput] = useState({
        periode: '',
        nonbreDeJour: 0,
        kilometrage: 0,
        montantDivers: 0,
        nombreDeRepas: 0,
    });
    async function update(keys, valueI) {
        if (keys === 'periode') {
            if ((await exiteFraisPeriode(valueI)) && +id === 0) {
                props.notification({ message: 'Vous avez déjà une note de frais créé pour cette periode' });
            } else {
                setDataInput((prevState) => ({
                    ...prevState,
                    [keys]: valueI,
                }));
            }
        } else {
            setDataInput((prevState) => ({
                ...prevState,
                [keys]: valueI,
            }));
        }
    }
    async function exiteFraisPeriode(valueI) {
        const data = {
            idAgent: user[usurpation].fk_id,
            anneeScolaire: user.idAnneeScolaire,
            listStatuts: [1, 2, 3, 4],
        };
        const response = await POST('/api/utilisateursAdministratifs/listFrais/', data);
        for (let index = 0; index < response.data.length; index++) {
            if (
                +('' + valueI.getMonth() + valueI.getFullYear()) ===
                +('' + new Date(response.data[index].periode).getMonth() + new Date(response.data[index].periode).getFullYear())
            ) {
                return true;
            }
        }
        return false;
    }
    function createJour(dataJ) {
        if (dataInput.periode || dataJ) {
            let elemDiv = [];
            let isEven;
            if (dataJ) {
                for (let elem = 0; elem < dataJ.length; elem++) {
                    if (elem % 2 === 0) {
                        isEven = {};
                    } else {
                        isEven = { backgroundColor: '#dadada' };
                    }
                    const data = (
                        <div className="ml2 mr2 border1darkgrey" style={isEven}>
                            <div className="flex ml1 mb1">
                                <h3 className="mb0 w100">Journée : {elem + 1}</h3>
                            </div>
                            <JourFrais jour={elem + 1} dataJ={dataJ[elem]} brouillon={brouillon} />.
                        </div>
                    );
                    elemDiv.push(data);
                    setElemJour(...elemJour, elemDiv);
                }
            } else {
                if (elemJour.length % 2 !== 0) {
                    isEven = { backgroundColor: '#dadada' };
                }
                elemDiv = (
                    <div className="ml2 mr2 border1darkgrey" style={isEven}>
                        <div className="flex ml1 mb1">
                            <h3 className="mb0 w100">Journée : {elemJour.length + 1}</h3>
                        </div>
                        <JourFrais jour={elemJour.length + 1} />
                    </div>
                );
                setElemJour([...elemJour, elemDiv]);
            }
        } else {
            props.notification({ message: 'Veuillez sélectionner une periode' });
        }
    }
    function delJour(index) {
        props.removeJour(elemJour.length);
        const tableau = elemJour;
        tableau.splice(index - 1, 1);
        setElemJour(tableau);
        setDelT(!delT);
    }
    function calculFrais() {
        const noMatch = ['periode', 'refreshJour'];
        let jourKeys = Object.keys(infoJour).filter((e) => !noMatch.includes(e));
        if (jourKeys.indexOf('refreshJour') !== -1) {
            jourKeys.splice(jourKeys.indexOf('refreshJour'), 1);
        }
        update('nonbreDeJour', Object.keys(jourKeys).length - 1 === -1 ? 0 : Object.keys(jourKeys).length - 1);
        let repasNb = 0;
        let kilometrage = 0;
        let diverMB = 0;
        for (let elem = 0; elem < jourKeys.length; elem++) {
            repasNb = repasNb + +infoJour[jourKeys[elem]].NBR;
            kilometrage = kilometrage + +infoJour[jourKeys[elem]].kilometrage;
            diverMB = diverMB + +infoJour[jourKeys[elem]].MDivers;
        }
        update('nombreDeRepas', repasNb);
        update('kilometrage', kilometrage);
        update('montantDivers', euroFormate(diverMB));
    }
    function ButtonSave() {
        let tableauBouton = testDroit(user[usurpation].roleFonction, fonctionFrais.Modification_statut)
            ? [
                  {
                      label: 'A compléter',
                      css: [css.ml30px, css.cVert, css.borderCvert, css.xs_w100, css.xs_nomargin, css.xs_mb2],
                      status: 1,
                  },
                  {
                      label: 'Instruction en cours',
                      css: [css.ml30px, css.cVert, css.borderCvert, css.xs_w100, css.xs_nomargin, css.xs_mb2],
                      status: 3,
                  },
                  { label: 'Acceptée', css: [css.ml30px, css.xs_w100, css.xs_nomargin, css.xs_mb2], status: 4 },
                  { label: 'Refusée', css: [css.ml30px, css.xs_w100, css.xs_nomargin, css.xs_mb2], status: 5 },
              ]
            : [];
        if (!type) {
            tableauBouton = [
                {
                    label: 'Mémoriser',
                    css: [css.ml30px, css.cVert, css.borderCvert, css.xs_w100, css.xs_nomargin, css.xs_mb2],
                    status: 1,
                },
                { label: 'Soumettre', css: [css.ml30px, css.xs_w100, css.xs_nomargin, css.xs_mb2], status: 2 },
            ];
        }
        const affichageBouton = () => {
            const bouton = [];
            for (let index = 0; index < tableauBouton.length; index++) {
                bouton.push(
                    <Button
                        disabled={brouillon}
                        variant="outlined"
                        color={tableauBouton[index].label === 'Refusée' ? 'secondary' : 'primary'}
                        size="small"
                        className={tableauBouton[index].css}
                        onClick={() => {
                            saveFrais(tableauBouton[index].status);
                        }}
                    >
                        {tableauBouton[index].label}
                    </Button>,
                );
            }
            return bouton;
        };
        return affichageBouton();
    }
    async function recupDataFrais() {
        const response = await GET(`/api/frais/${id}`);
        if (response.success) {
            update('periode', new Date(response.data.periode));
            props.fraisPeriode(new Date(response.data.periode));
            props.filtre([{ StatutFrais: response.data.fk_fraisStatut }]);
            // eslint-disable-next-line eqeqeq
            setBrouillon(response.data.fk_fraisStatut != 1 && !type ? true : false);
            // eslint-disable-next-line eqeqeq
            setGestion(response.data.fk_fraisStatut != 1 && !type ? true : false);
            createJour(response.data.fraisJournees);
            setIdUser(response.data.fk_user);
            setNomAgent(response.data.nomPrenomUtilisateur);
            let km = 0;
            let repas = 0;
            let montant = 0;
            for (let elem = 0; elem < response.data.fraisJournees.length; elem++) {
                km = km + response.data.fraisJournees[elem].kilometrage;
                repas = repas + response.data.fraisJournees[elem].nombreRepas;
                montant = montant + response.data.fraisJournees[elem].montantDivers;
            }
            update('montantDivers', euroFormate(montant));
            update('kilometrage', km);
            update('nombreDeRepas', repas);
            update('nonbreDeJour', response.data.fraisJournees.length);
            setVehicule(response.data.vehicule[0]);
        }
    }
    async function paiementOuverte() {
        const response = await GET('/api/parametres/list?categorie=general', undefined, { redirectOnError: false });
        let index = response.data.findIndex((e) => e.cle === 'fraisReculPeriode');

        if (index > -1) {
            setFraisReculPeriode(response.data[index].valeur);
        }
    }
    async function saveFrais(status) {
        const jour = Object.values(infoJour);
        const tableauJour = jour.filter((elem) => elem !== false);
        const fraisJournees = function () {
            const data = [];
            for (let elem = 0; elem < tableauJour.length; elem++) {
                const tableauTrajet = Object.values(tableauJour[elem]).filter((i) => {
                    return typeof i === 'object';
                });
                if (tableauTrajet.length !== 0) {
                    const dataTrajet = [];
                    for (let elem = 3; elem < tableauTrajet.length; elem++) {
                        const fraisTrajets = {
                            idFraisTrajet: null,
                            fk_fraisJournee: null,
                            ordre: elem + 1,
                            fk_siteA: tableauTrajet[elem].trajetD,
                            fk_siteB: tableauTrajet[elem].trajetV,
                            kilometrage: tableauTrajet[elem].km ? tableauTrajet[elem].km : 0,
                            temps: '00:00:00',
                        };
                        dataTrajet.push(fraisTrajets);
                    }
                    const dataJour = {
                        idFraisJournee: null,
                        fk_frais: null,
                        dateJour: DateHeureFormat({ BDD: 'date', date: tableauJour[elem].date }),
                        heureDepart: DateHeureFormat({ BDD: 'heure', date: tableauJour[elem].heureDepart }),
                        heureRetour: DateHeureFormat({ BDD: 'heure', date: tableauJour[elem].heureRetour }),
                        fk_fraisType: tableauJour[elem].typeJour,
                        motif: tableauJour[elem].motif,
                        nombreRepas: tableauJour[elem].NBR,
                        montantDivers: tableauJour[elem].MDivers,
                        kilometrage: tableauJour[elem].kilometrage,
                        fraisTrajets: dataTrajet,
                    };
                    data.push(dataJour);
                }
            }
            return data;
        };
        const data = {
            idFrais: id === 0 ? null : id,
            fk_anneeScolaire: user.idAnneeScolaire,
            fk_fraisStatut: status,
            dateDemande: dateSave(),
            fk_user: !idUser ? user[usurpation].fk_id : idUser,
            periode: DateHeureFormat({ BDD: 'date', date: dataInput.periode }),
            fraisJournees: fraisJournees(),
        };
        let testData = true;
        if (data.periode === '') {
            testData = false;
        }
        for (let elem = 0; elem < data.fraisJournees.length; elem++) {
            if (
                data.fraisJournees[elem].dateJour === '' ||
                data.fraisJournees[elem].heureDepart === '' ||
                data.fraisJournees[elem].heureRetour === '' ||
                data.fraisJournees[elem].fk_fraisType === ''
            ) {
                testData = false;
            }
        }
        if (testData === true) {
            const response = await POST('/api/frais/0 ', data, {
                redirectOnError: false,
            });
            if (response.success === true) {
                if (response.data.fk_fraisStatut === 3) {
                    history.push(`/liste-gestion-frais`);
                } else {
                    history.push(`/fiche-frais/${response.data.idFrais}${type === 'gestion' ? '/gestion' : ''}`);
                }
                props.filtre([{ StatutFrais: response.data.fk_fraisStatut }]);
                const notificationStatus = [
                    "Cette note de frais est enregistrée comme brouillon. Elle peut être complétée avant d'être soumise pour validation.",
                    'Cette note de frais a été soumise à validation',
                    'Cette note de frais est en cour de validation',
                    'Cette note de frais a été  validée',
                    'Cette note de frais a été refusée',
                ];
                props.notification({
                    message: notificationStatus[status - 1],
                    status: true,
                });
            }
        } else {
            props.notification({ message: 'Tous les champs date, heure et type doivent être remplis' });
        }
    }
    function periode(periode) {
        if (periode.getMonth() === (!infoJour.periode ? null : infoJour.periode.getMonth())) {
            return periode;
        }
        if (Object.keys(infoJour).includes('jour 1')) {
            props.notification({ message: 'Vous devez supprimer les journées si vous voulez changer de periode' });
            return dataInput.periode;
        }
        return periode;
    }
    useEffect(() => {
        props.removeAll();
        props.filtre([{ StatutFrais: 1 }]);
        if (id !== '0') {
            recupDataFrais();
        }
    }, []);
    useEffect(() => {
        paiementOuverte();
        calculFrais();
    }, [delT, refreshJour]);
    return (
        <div>
            <div>
                <div className="ml2 mr2 ">
                    <div className="flex ">
                        <h3 className="mb0 w75">Edition d'une demande de frais : {nomAgent}</h3>
                        <h3 className="mb0 w25 ">{`${vehicule.vehiculeMarque} ${vehicule.vehiculeModele} ${vehicule.vehiculePuissance}CV`}</h3>
                    </div>
                    <hr></hr>
                </div>
                <div className="flex xs_directionColumn">
                    <div className="w50 xs_w90 xs_ml2">
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Statut</label>
                            <SelectSimple
                                disabled={true}
                                native={true}
                                tableau={StatutFrais}
                                indice={'StatutFrais'}
                                classe={[css.nomargin, css.ml30px, css.w50]}
                            ></SelectSimple>
                        </div>
                        <DateInput
                            openTo="month"
                            label={'periode'}
                            disabled={gestion}
                            views={['year', 'month']}
                            value={dataInput.periode}
                            className={[css.nomargin, css.ml30px, css.w50]}
                            maxDate={Date(date)}
                            minDate={new Date(date.setMonth(date.getMonth() - +fraisReculPeriode))}
                            onChange={(event) => {
                                update('periode', new Date(periode(event)));
                                props.fraisPeriode(new Date(periode(event)));
                            }}
                        />
                    </div>
                    <div className="w50 xs_w90 xs_ml2">
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Nombre de journée</label>
                            <Input
                                disabled={true}
                                value={dataInput.nonbreDeJour}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    update('nonbreDeJour', event.target.value);
                                }}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Kilométrage</label>
                            <Input
                                disabled={true}
                                value={dataInput.kilometrage}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    update('kilometrage', event.target.value);
                                }}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Nombre de repas</label>
                            <Input
                                disabled={true}
                                value={dataInput.nombreDeRepas}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    update('nombreDeRepas', event.target.value);
                                }}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Montant Divers</label>
                            <Input
                                disabled={true}
                                value={dataInput.montantDivers}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    update('montantDivers', event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {type ? <ButtonSave /> : <></>}
            </div>
            <br />
            <div>{elemJour}</div>
            <Button
                disabled={gestion}
                variant="outlined"
                className={[css.ml30px, css.cVert, css.borderCvert, css.xs_w100, css.xs_nomargin, css.xs_mb2, css.xs_mt5]}
                onClick={() => createJour()}
            >
                Ajouter une journée
            </Button>
            <Button
                disabled={gestion}
                color="secondary"
                variant="outlined"
                className={[css.m2, css.xs_w100, css.xs_nomargin, css.xs_mb2]}
                onClick={() => {
                    delJour(elemJour.length);
                }}
            >
                Supprimer cette journée
            </Button>
            <div className="xs_flex xs_directionColumn">
                <ButtonSave />
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        removeJour(nb) {
            dispatch({ type: 'removeJour', nb });
        },
        removeAll() {
            dispatch({ type: 'removeAll' });
        },
        fraisPeriode(periode) {
            dispatch({ type: 'periode', periode });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
export default connect(null, mapDispatchToProps)(Frais);
