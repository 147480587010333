/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Switch, TextareaAutosize, Checkbox, FormControl, Select } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import Reduire from '../../components/organism/Reduire/Reduire';
import Input from '../../components/atoms/TextField';
import Button from '../../components/atoms/ButtonCustom';
import { copyArrayOfObject } from '../../components/Utils/newPointeurArrayObject';
import DialogCustom from '../../components/organism/Dialog/dialog';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const Groupe = (props) => {
    const history = useHistory();
    const css = useStylesMaterielUIGlobal();
    const [dataGroupeRole, setDataGroupeRole] = useState([]);
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const [listeFonctionDansGroupe, setListeFonctionDansGroupe] = useState([]);
    const [reduirAll, setReduirAll] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idGroupe, setIdGroupe] = useState();
    const [dataGroupe, setDataGroupe] = useState({
        idGroupe: null,
        libelleGroupe: '',
        supprimable: 1,
        commentaire: '',
    });
    let { id } = useParams();

    function Modal() {
        return (
            <DialogCustom
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                children={
                    <>
                        <div className="flex w100 directionColumn">
                            <label>Sèlectionner un groupe:</label>
                            <FormControl className={[css.w80, css.mt5]}>
                                <Select native={true} onChange={(event) => setIdGroupe(event.target.value)}>
                                    <option value={''}></option>
                                    {listeGroupeRole.map((elem, index) => {
                                        return (
                                            <option key={index} value={elem.idGroupe}>
                                                {elem.libelleGroupe}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                disabled={!idGroupe}
                                variant="outlined"
                                size="small"
                                className={[css.mb5, css.mt15, css.w100]}
                                onClick={() => {
                                    ListeFonction(idGroupe, true);
                                    setOpenModal(false);
                                }}
                            >
                                Charger les rôles du groupe
                            </Button>
                        </div>
                    </>
                }
            />
        );
    }

    async function requeteListeFonction() {
        let response = await GET(`/api/gestionRole/ListeFonction`);
        setDataGroupeRole(response.data);
        if (+id !== 0) {
            response = await GET(`/api/gestionRole/groupe?idGroupe=${id}`);
            setDataGroupe(response.data);
            ListeFonction(id);
        }
        if (listeGroupeRole.length === 0) {
            response = await GET(`/api/gestionRole/listeGroupe`, undefined, {
                redirectOnError: false,
            });
            setListeGroupeRole(response.data);
        }
    }
    async function ListeFonction(idGroupe, duplique) {
        const response = await GET(`/api/gestionRole/ListeFonctionParGroupe?idGroupe=${idGroupe}`);
        if (duplique) {
            for (let index = 0; index < response.data.length; index++) {
                response.data[index].fk_groupe = id;
            }
        }
        setListeFonctionDansGroupe(response.data);
    }
    function checked(idFonction) {
        const findID = listeFonctionDansGroupe.findIndex((e) => e.fk_fonction === idFonction);
        if (findID === -1) {
            return false;
        } else {
            return true;
        }
    }
    function updateFonctionParGroupe(idFonction, fk_parent) {
        const findFonction = listeFonctionDansGroupe.findIndex((e) => e.fk_fonction === idFonction);
        if (findFonction === -1) {
            setListeFonctionDansGroupe([
                ...listeFonctionDansGroupe,
                {
                    fk_groupe: +id,
                    fk_fonction: idFonction,
                    fk_parent: fk_parent,
                },
            ]);
        } else {
            const newFonctionGroupe = copyArrayOfObject(listeFonctionDansGroupe);
            const niveau1 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                if (listeFonctionDansGroupe[index].fk_parent === idFonction) {
                    niveau1.push(listeFonctionDansGroupe[index]);
                    const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                    newFonctionGroupe.splice(findFonction, 1);
                }
            }
            const niveau2 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                for (let elem = 0; elem < niveau1.length; elem++) {
                    if (listeFonctionDansGroupe[index].fk_parent === niveau1[elem].fk_fonction) {
                        niveau2.push(listeFonctionDansGroupe[index]);
                        const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                        newFonctionGroupe.splice(findFonction, 1);
                    }
                }
            }
            const niveau3 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                for (let elem = 0; elem < niveau2.length; elem++) {
                    if (listeFonctionDansGroupe[index].fk_parent === niveau2[elem].fk_fonction) {
                        niveau3.push(listeFonctionDansGroupe[index]);
                        const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                        newFonctionGroupe.splice(findFonction, 1);
                    }
                }
            }
            const suppressionFonction = newFonctionGroupe.filter((e) => e.fk_fonction !== idFonction);
            setListeFonctionDansGroupe(suppressionFonction);
        }
    }
    async function saveGroupe() {
        const data = {
            idGroupe: +id,
            supprimable: dataGroupe.supprimable,
            libelleGroupe: dataGroupe.libelleGroupe,
            commentaire: dataGroupe.commentaire,
            fonctionsGroupe: { fk_groupe: +id, listeFonction: listeFonctionDansGroupe },
        };
        if (!data.libelleGroupe)
            return props.notification({
                message: 'Vous devez nommer le groupe',
            });
        const response = await POST(`/api/gestionRole/${id}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            props.notification({
                message: "L'enregistrement ok",
                status: response.success,
            });
            history.push(`/groupe-role/${response.data.idGroupe}`);
        } else {
            props.notification({
                message: "L'enregistrement a rencontré un probleme, veuillez contacter votre adminitrateur",
            });
        }
    }
    function updateInput(keys, valueI) {
        setDataGroupe((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    useEffect(() => {
        props.addNomPage(`Fonction du groupe`);
        requeteListeFonction();
    }, []);

    return (
        <div>
            {Modal()}
            <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => window.scrollTo(0, 0)}
                style={{ position: 'fixed', bottom: '50px', left: '220px' }}
            >
                Haut de page
            </Button>
            <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => setReduirAll(!reduirAll)}
                style={{ position: 'fixed', bottom: '10px', left: '220px' }}
            >
                {!reduirAll ? 'Tout reduire' : 'Tout agrandir'}
            </Button>
            <div className=" mt2 ml10 mr10 p2 flex alignICenter ">
                <div className="w80">
                    <div className="flex alignICenter ">
                        <label className="w25 ">Nom du groupe :</label>
                        <Input className={css.w60} value={dataGroupe.libelleGroupe} onChange={(event) => updateInput('libelleGroupe', event.target.value)} />
                    </div>
                    <div className="flex alignICenter mt2 ">
                        <label className="w25  ">Commentaire :</label>
                        <TextareaAutosize
                            value={dataGroupe.commentaire}
                            rowsMin={5}
                            className={css.w60}
                            onChange={(event) => updateInput('commentaire', event.target.value)}
                        />
                    </div>
                    <div className="flex alignICenter ">
                        <label>Ce groupe peut etre suppimer :</label>
                        <Checkbox
                            className={css.ml2}
                            checked={dataGroupe.supprimable}
                            onChange={() => updateInput('supprimable', dataGroupe.supprimable ? 0 : 1)}
                        />
                    </div>
                </div>
                <div>
                    {+id !== 0 ? (
                        <Button variant="outlined" size="small" className={[css.w100, css.mb10]} onClick={() => setOpenModal(true)}>
                            Dupliquer un Groupe
                        </Button>
                    ) : null}
                    {+id !== 1 ? (
                        <Button variant="outlined" size="small" color="primary" className={[css.w100, css.mb10]} onClick={() => saveGroupe()}>
                            Enregistrer le groupe
                        </Button>
                    ) : null}
                    <Link to="/liste-groupe-role" className={css.w100}>
                        <Button variant="outlined" size="small" color="secondary" className={css.w100}>
                            retour
                        </Button>
                    </Link>
                </div>
            </div>
            {+id !== 0 ? (
                <>
                    <h3 className="textCenter">Droit pour le groupe</h3>
                    <div className="ficheContainer w80 ml10 ">
                        {dataGroupeRole.map((row, index) => (
                            <Reduire className={[css.block]} titreDisplayNone={`${index + 1} ${row.libelleFonction}`} none={reduirAll}>
                                <div key={index} className="w100 border4 radius4 mb1">
                                    <div className="flex spaceBetween bgcdarkgreyOpacity0_3 pl1 alignICenter">
                                        <p>{`${index + 1} ${row.libelleFonction}`}</p>
                                        <p>{row.commentaire}</p>
                                        <div>
                                            <Switch
                                                checked={checked(row.idFonction)}
                                                onChange={() => {
                                                    updateFonctionParGroupe(row.idFonction, null);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <hr className="nomargin cBleu2c70c6 opacity0_7"></hr>
                                    {row.niveau1
                                        ? row.niveau1.map((r, i) => (
                                              <div key={i}>
                                                  <div className="flex  bgcSilverOpacity0_3 pl1 alignICenter">
                                                      <p className="ml2 w50">{`${i + 1} ${r.libelleFonction}`}</p>
                                                      <p className="w50">{r.commentaire}</p>
                                                      <div>
                                                          <Switch
                                                              disabled={!checked(r.fk_parent)}
                                                              checked={checked(r.idFonction)}
                                                              onChange={() => {
                                                                  updateFonctionParGroupe(r.idFonction, r.fk_parent);
                                                              }}
                                                          />
                                                      </div>
                                                  </div>
                                                  <hr className="nomargin cBleu2c70c6 opacity0_7"></hr>
                                                  {r.niveau2
                                                      ? r.niveau2.map((elem, x) => (
                                                            <div key={x}>
                                                                <div className="flex  bgclightgreyOpacity0_3 pl1 alignICenter">
                                                                    <p className="ml4 w45">
                                                                        {elem.niveau3 ? `${x + 1} ${elem.libelleFonction}` : elem.libelleFonction}
                                                                    </p>
                                                                    <p className="w50">{elem.commentaire}</p>
                                                                    <div>
                                                                        <Switch
                                                                            disabled={!checked(elem.fk_parent)}
                                                                            checked={checked(elem.idFonction)}
                                                                            onChange={() => {
                                                                                updateFonctionParGroupe(elem.idFonction, elem.fk_parent);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr className="nomargin cBleu2c70c6 opacity0_7"></hr>
                                                                {elem.niveau3
                                                                    ? elem.niveau3.map((e, y) => (
                                                                          <div key={y}>
                                                                              <div className=" flex  bgcgainsboroOpacity0_3 pl1 alignICenter">
                                                                                  <p className="ml8 w40">{e.libelleFonction}</p>
                                                                                  <p className="w50">{e.commentaire}</p>
                                                                                  <div>
                                                                                      <Switch
                                                                                          disabled={!checked(e.fk_parent)}
                                                                                          checked={checked(e.idFonction)}
                                                                                          onChange={() => {
                                                                                              updateFonctionParGroupe(e.idFonction, e.fk_parent);
                                                                                          }}
                                                                                      />
                                                                                  </div>
                                                                              </div>
                                                                              <hr className="nomargin cBleu2c70c6 opacity0_7"></hr>
                                                                          </div>
                                                                      ))
                                                                    : null}
                                                            </div>
                                                        ))
                                                      : null}
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </Reduire>
                        ))}
                    </div>
                </>
            ) : (
                <p className="textCenter mt10">Vous devez Enregistrer le groupe anvant de pouvoir administrer les rôles.</p>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
export default connect(null, mapDispatchToProps)(Groupe);
