/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Container from '../../components/atoms/Container/container';
import { GET, DELETE } from '../../components/Utils/requete';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import ButtonLivret from './ButtonLivret';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionLivret from '../../fonctionsRoles/livret';

const ListeTypeLivret = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [dataLivret, setDataLivret] = useState([]);
    const [refreshModel, setRefreshModel] = useState(false);
    const tableHead = [
        { id: 'titre', label: 'Titre' },
        { id: 'visible', label: 'Visible' },
    ];
    async function recupDataLivret() {
        const response = await GET('/api/livrets/types/list', undefined, { redirectOnError: false });
        setDataLivret(response.data);
    }
    async function supprModel(id) {
        await DELETE(`/api/livrets/types/${id}`);
        setRefreshModel(!refreshModel);
    }
    useEffect(() => {
        props.addNomPage('Liste des modèles de livret');
        recupDataLivret();
    }, [refreshModel]);
    return (
        <div className="listeContainer">
            <Container>
                {testDroit(user[usurpation].roleFonction, fonctionLivret.Creation_nouveau_model) ? (
                    <ButtonLivret url="/fiche-livret/0/creation" type="button" label="Nouveau Modèle" className={[css.floatRight, css.m2]} />
                ) : null}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {setData(props.recherche.fn, dataLivret, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    return (
                                        <TableRow key={index} className={css.trbody}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Type livret</div>
                                                {testDroit(user[usurpation].roleFonction, fonctionLivret.Suppression_model) ? (
                                                    <ButtonLivret
                                                        tooltip="supprimer ce modèle"
                                                        type="icone"
                                                        color="secondary"
                                                        label={<DeleteForeverIcon />}
                                                        className={[css.p0, css.ml10px, css.floatRight]}
                                                        onClick={() => supprModel(row.idLivretType)}
                                                    />
                                                ) : null}
                                                <Link to={`/fiche-livret/${row.idLivretType}/creation`}>{row.titre}</Link>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Visible</div>
                                                {row.visible ? 'oui' : 'non'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeTypeLivret);
