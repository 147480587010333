/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Select } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Pageview from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { DELETE, GET, POST } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import ExportExcel from '../../../components/organism/ExportTableau/exportExcel';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import { suppressionDoublon } from '../../../components/Utils/suppressionDoublon';
import Contact from '../../../components/organism/Contact/Contact';
import ExcuseEleveAtelier from '../../../components/organism/ExcuseEleve/excuseEleveAtelier';
import { testDroit } from '../../../components/Utils/testDroit';
import { ButtonCustom } from './Fiche';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadEleveF, tableHeadInfoEleveF, tableHeadInfoEleveExport, selectDialogAddEleve } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

const AtelierEleves = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const valueFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    const [tableauFiltre, setTableauFiltre] = useState({
        fk_statut: [],
        fk_instrument: [],
        Eleve: [],
        fk_scolariteInterne: [],
        NomEleve: '',
    });
    const [dataEleve, setDataEleve] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [mail, setMail] = useState([]);
    const [dataSI, setDataSI] = useState({
        fk_eleve: null,
        fk_atelier: props.id,
        fk_instrument: null,
        fk_statut: null,
        fk_scolariteInterne: null,
    });
    function Modal() {
        let content;
        let fullScreen = false;
        switch (open.event) {
            case 'supprEleve':
                content = (
                    <>
                        <span className="mr2 textCenter w100 block">Voulez-vous supprimer cet élève</span>
                        <div className="mt2 textCenter">
                            <ButtonCustom
                                type="bouton"
                                label="Non"
                                onClick={() => setOpen({ open: false })}
                                color="secondary"
                                className={[css.mr20, css.mt2]}
                            />
                            <ButtonCustom
                                type="bouton"
                                label="Oui"
                                onClick={() => {
                                    suppressionEleve(open.params);
                                }}
                                suppr="supprEnseignant"
                            />
                        </div>
                    </>
                );
                break;
            case 'updateEleve':
            case 'addEleve':
                content = (
                    <>
                        {open.event === 'addEleve' && open.event !== 'updateEleve' ? (
                            <>
                                <h3>Ajouter un élève</h3>
                                <div className="ml30px flex directionColumn mt2">
                                    <labal>Elève:</labal>
                                    <Autocomplete
                                        options={tableauFiltre.Eleve}
                                        getOptionLabel={(option) => option.nomPrenomEleve}
                                        renderInput={(params) => <TextField {...params} />}
                                        className={[css.nomargin, css.w80, css.ml30px]}
                                        onChange={(event, newValue) => {
                                            if (newValue) filtreAddEleve(newValue.idEleve);
                                        }}
                                    />
                                </div>
                            </>
                        ) : open.event === 'updateEleve' ? (
                            <h3>Modifier les informations de {tableauFiltre.NomEleve} </h3>
                        ) : null}
                        {selectDialogAddEleve.map((row) => (
                            <div className="ml30px flex directionColumn mt2">
                                <labal>{row.label}:</labal>
                                <Select
                                    onChange={(event) => {
                                        setDataSI((prevState) => ({
                                            ...prevState,
                                            [row.indice]: event.target.value,
                                        }));
                                        if (row.indice === 'fk_scolariteInterne') selectInfoSI(tableauFiltre[row.indice], event.target.value);
                                    }}
                                    value={dataSI[row.indice]}
                                    className={[css.w80, css.ml30px]}
                                >
                                    <option value={''}></option>
                                    {tableauFiltre[row.indice].map((elem, index) => {
                                        let idName = Object.keys(elem)[0];
                                        let libName = Object.keys(elem)[1];
                                        if (row.indice === 'fk_scolariteInterne') {
                                            idName = 'idScolarite';
                                            libName = 'libelleOffre';
                                        }
                                        return (
                                            <option key={index} value={elem[idName]}>
                                                {elem[libName]}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </div>
                        ))}
                        <div className="mt10 textCenter mb2">
                            <ButtonCustom type="bouton" label="Valider" onClick={() => associerEleve()} className={css.mr20} />
                            <ButtonCustom type="bouton" label="Retour" onClick={() => setOpen({ open: false })} color="secondary" />
                        </div>
                    </>
                );
                break;
            case 'infoEleve':
                fullScreen = true;
                content = (
                    <>
                        <ButtonCustom type="bouton" label="Retour" onClick={() => setOpen({ open: false })} color="secondary" />
                        <h3>Détail des élèves de l'atelier</h3>
                        <div className="flex alignICenter mb2">
                            <div className="flex w100 flexEnd">
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Export_excel) ? (
                                    <ExportExcel data={dataEleve} head={tableHeadInfoEleveExport} nomFichier="Liste élèves" />
                                ) : null}
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Envoi_email) ? (
                                    <div>
                                        <ButtonCustom type="bouton" label={<Contact email={mail} lib="Email à tous" />} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadInfoEleveF}></TableHeadCustom>
                                <TableBody>
                                    {dataEleve.map((row, index) => {
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Elève</div>
                                                    {row.nomPrenomEleve}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Statut</div>
                                                    {row.libelleStatut}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Mobile</div>
                                                    {suppressionDoublon(`${row.concatTelephoneMobile},${row.concatTelephoneFixe}`).map((e) => (
                                                        <>
                                                            <Contact telephone={e} />
                                                        </>
                                                    ))}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Email</div>
                                                    {suppressionDoublon(row.concatEmails).map((e) => (
                                                        <Contact email={e} />
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                );
                break;
            default:
                break;
        }
        return (
            <DialogCustom
                open={open.open}
                onClose={() => {
                    setOpen({ open: false, content: '' });
                }}
                children={content}
                fullScreen={fullScreen}
            />
        );
    }
    function updateState(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    async function ElevesAtelier() {
        const responseEleve = await POST('/api/ateliers/elevesByAteliers', {
            fk_atelier: props.id,
        });
        let tableauMail = [];
        for (let elem = 0; elem < responseEleve.data.length; elem++) {
            tableauMail.push(responseEleve.data[elem].concatEmails);
        }
        setMail(tableauMail);
        setDataEleve(responseEleve.data);
        if (tableauFiltre.fk_statut.length === 0) {
            const requete = await GET('/api/ateliers/ateliersStatut');
            updateState('fk_statut', requete.data);
            const dataEleve = {
                fk_anneeScolaire: valueFiltre.AnneeScolaire,
                fk_atelierType: valueFiltre.SousGroupeAtelier,
            };
            const eleveFiltre = await POST('/api/eleves/listElevesFromAnneeScolaireAndAtelierType/', dataEleve);
            updateState('Eleve', eleveFiltre.data);
            const dataInstrument = {
                fk_anneeScolaire: valueFiltre.AnneeScolaire,
                fk_site: [valueFiltre.Site],
            };
            const instrumentFiltre = await POST('/api/cotisations/instruments', dataInstrument);
            updateState('fk_instrument', instrumentFiltre.data);
        }
    }

    async function associerEleve() {
        if (dataSI.fk_instrument === '' || dataSI.fk_statut === '' || dataSI.fk_scolariteInterne === '') {
            return props.notification({ message: 'Tous les champs doivent étre rempli' });
        } else {
            await POST('/api/ateliers/attachedEleve', dataSI);
            setDataSI({
                fk_eleve: null,
                fk_atelier: props.id,
                fk_instrument: null,
                fk_statut: null,
                fk_scolariteInterne: null,
            });
            setOpen({ open: false });
        }
        return ElevesAtelier();
    }

    async function suppressionEleve(idUser) {
        const data = {
            fk_eleve: idUser,
            fk_atelier: props.id,
        };
        await DELETE('/api/ateliers/attachedEleve', data);
        setOpen({ open: false });
        ElevesAtelier();

        setOpen({ open: false });
        return ElevesAtelier();
    }
    function selectInfoSI(array, fk_scolariteInterne) {
        const infoFiltre = array.filter((e) => +e.idScolarite === +fk_scolariteInterne);
        if (infoFiltre !== 0) {
            setDataSI((prevState) => ({
                ...prevState,
                fk_instrument: infoFiltre[0].fk_instrument,
            }));
            setDataSI((prevState) => ({
                ...prevState,
                fk_statut: infoFiltre[0].fk_statut,
            }));
        }
    }

    async function filtreAddEleve(id, lecture) {
        const response = await GET(`/api/eleves/${id}/SI_list?fk_anneeScolaire=${valueFiltre.AnneeScolaire}`);
        updateState('fk_scolariteInterne', response.data);
        if (!lecture) {
            setDataSI((prevState) => ({
                ...prevState,
                fk_eleve: id,
            }));
            if (response.data.length < 2) {
                setDataSI((prevState) => ({
                    ...prevState,
                    fk_scolariteInterne: response.data[0].idScolarite,
                }));
                selectInfoSI(response.data, response.data[0].idScolarite);
            }
        }
    }
    useEffect(() => {
        ElevesAtelier();
    }, []);
    useEffect(() => {
        Modal();
    }, [dataSI.fk_scolariteInterne]);
    return (
        <div>
            {Modal()}
            <div className="flex mt5">
                <h3 className="w100 nomargin">Élèves </h3>
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Information_eleves) ? (
                    <ButtonCustom
                        type="icone"
                        label={<Pageview />}
                        onClick={() => {
                            setOpen({ open: true, event: 'infoEleve' });
                        }}
                        className={[css.floatRight, css.p0, css.cVert]}
                    />
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_eleve) ? (
                    <ButtonCustom
                        disabled={+props.id === 0}
                        type="icone"
                        label={<AddBoxIcon />}
                        onClick={() => {
                            props.filtre([{ Eleve: '' }, { ScolariteInterne: '' }, { Instrument: '' }, { Statut: '' }]);
                            setOpen({ open: true, event: 'addEleve' });
                        }}
                        className={[css.floatRight, css.p0, css.cVert, css.xs_mr5]}
                    />
                ) : null}
            </div>
            {+props.maximumEleves > 0 ? <p className="nomargin fontSize0_9em">nombre d'élève {`${dataEleve.length}/${props.maximumEleves}`}</p> : null}
            <hr></hr>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadEleveF}></TableHeadCustom>
                    <TableBody>
                        {dataEleve.map((row, index) => {
                            const button = (
                                <>
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_eleve) ? (
                                        <ButtonCustom
                                            type="icone"
                                            label={<DeleteForeverIcon />}
                                            onClick={() => {
                                                setOpen({ open: true, event: 'supprEleve', params: row.fk_eleve });
                                            }}
                                            className={[css.floatRight, css.p0]}
                                            color="secondary"
                                        />
                                    ) : null}
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Modifier_eleve) ? (
                                        <ButtonCustom
                                            type="icone"
                                            label={<EditIcon />}
                                            onClick={() => {
                                                filtreAddEleve(row.fk_eleve, true);
                                                updateState('NomEleve', row.nomPrenomEleve);
                                                setDataSI({
                                                    fk_eleve: row.fk_eleve,
                                                    fk_atelier: props.id,
                                                    fk_instrument: row.fk_instrument,
                                                    fk_statut: row.fk_statut,
                                                    fk_scolariteInterne: row.fk_scolariteInterne,
                                                });
                                                setOpen({
                                                    open: true,
                                                    event: 'updateEleve',
                                                });
                                            }}
                                            className={[css.floatRight, css.p0]}
                                        />
                                    ) : null}
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Presences_élève) ? (
                                        props.dataAtelier.jour ? (
                                            <ExcuseEleveAtelier dataAtelier={props.dataAtelier} idEleve={row.fk_eleve} nomEleve={row.nomPrenomEleve} />
                                        ) : null
                                    ) : null}
                                </>
                            );
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>
                                            Elève
                                            {window.matchMedia('(max-width: 576px)').matches ? button : null}
                                        </div>
                                        {window.matchMedia('(min-width: 576px)').matches ? button : null}
                                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_le_profil_eleve) ? (
                                            <Link to={`/fiche-eleve/${row.fk_eleve}`}>{row.nomPrenomEleve}</Link>
                                        ) : (
                                            row.nomPrenomEleve
                                        )}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        {row.libelleInstrument ? <div>Instrument</div> : null}
                                        {row.libelleInstrument}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Statut</div>
                                        {row.libelleStatut}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Date Creation</div>
                                        {new Date(row.dateCreation).toLocaleString('fr-FR')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(AtelierEleves);
