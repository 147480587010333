/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Page d'edition d'information'
 *
 * @copyright Horizon-Pharma
 */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { Button, Select, Input, Tooltip, Switch } from 'antd';

import { POST, GET } from '../../components/Utils/requete';
import Container from '../../components/atoms/Container/container';
import dateSave from '../../components/Utils/dateSave';
import { testDroit } from '../../components/Utils/testDroit';
import valuesQuery from '../../components/Utils/valuesQuery';
import formatSelect from '../../components/Utils/FormatSelect';

import * as fonctionInformation from '../../fonctionsRoles/information';

function EditionInformation(props) {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    let { id } = useParams();
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const [dataInformation, setDataInformation] = useState({ message: '', visible: 0, titre: '', responsable: false, groupe: [] });

    async function saveText() {
        let groupe = dataInformation.groupe;
        if (valuesQuery('type') === 'syndicat') {
            groupe = [];
            for (let index = 0; index < listeGroupeRole.length; index++) {
                groupe.push(listeGroupeRole[index].value);
            }
        }
        const data = {
            idInformation: id === '0' ? null : id,
            fk_utilisateur: user.user.fk_id,
            destinataires: dataInformation.responsable ? 'Responsable' : null,
            date: dateSave(),
            visible: dataInformation.visible,
            titre: dataInformation.titre,
            message: dataInformation.message,
            groupe: groupe,
            type: valuesQuery('type'),
        };
        await POST(`/api/informations/${id}`, data, { redirectOnError: false });
        props.notification({
            message: dataInformation.visible
                ? 'ce message a été enregistré et rendu visible'
                : "Ce message a été enregistré comme brouillon. Le message ne sera pas visible sur la page d'accueil",
            status: true,
        });
    }

    async function destinataireListe() {
        const response = await GET(`/api/gestionRole/listeGroupe`);
        setListeGroupeRole(formatSelect(response.data, 'idGroupe', 'libelleGroupe'));
    }

    async function recupData() {
        const response = await GET(`/api/informations/${id}`);
        if (response.success) {
            setDataInformation({
                titre: response.data.titre,
                responsable: response.data.destinataires ? response.data.destinataires[0] : '',
                groupe: response.data.groupe ? response.data.groupe : [],
                visible: response.data.visible,
                message: response.data.message,
            });
            props.addNomPage('Edition Information - ' + response.data.titre);
        }
    }
    useEffect(() => {
        if (id !== '0') recupData();
        destinataireListe();
    }, []);

    return (
        <Container>
            <div className="xs_flex xs_directionColumn">
                {valuesQuery('type') === 'direction' && (
                    <Select
                        placeholder="Destinataire"
                        mode="multiple"
                        className="ml2 mr2 w25 xs_w90 mb2"
                        value={dataInformation.groupe}
                        onChange={(values) =>
                            setDataInformation((prevState) => ({
                                ...prevState,
                                groupe: values,
                            }))
                        }
                        options={listeGroupeRole}
                    />
                )}
                <Input
                    value={dataInformation.titre}
                    placeholder="Titre"
                    className="ml2 w50 xs_w90"
                    onChange={({ target }) => {
                        setDataInformation((prevState) => ({
                            ...prevState,
                            titre: target.value,
                        }));
                    }}
                />
            </div>
            {valuesQuery('type') === 'direction' && (
                <div className="ml2 flex alignICenter">
                    <Switch
                        className="mr1"
                        checked={dataInformation.responsable ? true : false}
                        onChange={() =>
                            setDataInformation((prevState) => ({
                                ...prevState,
                                responsable: dataInformation.responsable ? '' : 'responsable',
                            }))
                        }
                    />
                    <label>A destination des responsables</label>
                </div>
            )}
            <div className="m5">
                <SunEditor
                    onChange={(elem) => {
                        setDataInformation((prevState) => ({
                            ...prevState,
                            message: elem,
                        }));
                    }}
                    setContents={dataInformation.message}
                    height="300"
                    setOptions={{
                        buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image'],
                        ],
                    }}
                />
            </div>
            <div className="ml2 flex alignICenter">
                <Tooltip placement="bottom" title="Ce message sera rendu visible sur la page d'accueil" color={'#0f4c81'}>
                    <Switch
                        className="mr1"
                        checked={dataInformation.visible}
                        onChange={() => {
                            setDataInformation((prevState) => ({
                                ...prevState,
                                visible: dataInformation.visible ? 0 : 1,
                            }));
                        }}
                    />
                </Tooltip>
                <label>Visible</label>
            </div>
            <div className="m2 xs_flex xs_directionColumn">
                {user.userC === 'user' &&
                    (testDroit(user[usurpation].roleFonction, fonctionInformation.Modification_information) ||
                        testDroit(user[usurpation].roleFonction, fonctionInformation.Modification_information_CST)) && (
                        <Link to={`/liste-informations/${valuesQuery('type')}`}>
                            <Button className="cbleu border1bleu m2 xs_w90" onClick={() => saveText()}>
                                ENREGISTRER
                            </Button>
                        </Link>
                    )}
                {user.userC === 'user' &&
                    (testDroit(user[usurpation].roleFonction, fonctionInformation.Creation_information) ||
                        testDroit(user[usurpation].roleFonction, fonctionInformation.Creation_information_CST)) && (
                        <Link to={`/edition-information/0?type=${valuesQuery('type')}`}>
                            <Button
                                className="cVert border1vert m2 xs_w90"
                                onClick={() => setDataInformation({ message: '', visible: 0, titre: '', responsable: false, groupe: [] })}
                            >
                                NOUVEAU MESSAGE
                            </Button>
                        </Link>
                    )}
                <Link to={`/liste-informations/${valuesQuery('type')}`}>
                    <Button danger className="xs_w90 m2 xs_ml2">
                        RETOUR
                    </Button>
                </Link>
            </div>
        </Container>
    );
}
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(EditionInformation);
