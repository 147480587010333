/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControl, Button, IconButton, Select } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GET, POST, DELETE } from '../../../components/Utils/requete';
import PaginationTable from '../../../components/organism/Table/Pagination';
import TableRecherche from '../../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import setData from '../../../components/organism/Table/setDataTableau';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';
import { rolePMT } from '../../../components/Utils/consts';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import * as fonctionEleve from '../../../fonctionsRoles/eleve';

const tableHeadAjoutResponsable = [
    { id: 'nomPrenomResponsable', label: 'Nom Prenom' },
    { id: 'telephoneFixe', label: 'Fixe' },
    { id: 'telephoneMobile', label: 'Mobile' },
];

const tableHeadResponsable = [
    { id: 'nomPrenomResponsable', label: 'Nom Prenom' },
    { id: 'fixeResponsable', label: 'Fixe' },
    { id: 'mobileResponsable', label: 'Mobile' },
    { id: 'roleResponsable', label: 'Role' },
    { id: 'emailResponsable', label: 'Email' },
    { id: '', label: 'Ordre' },
];

const ResponsableEleve = (props) => {
    let { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const css = useStylesMaterielUIGlobal();
    const usurpation = user.userC;
    const matchDisplay = window.matchMedia('(max-width: 576px)').matches;
    const [open, setOpen] = useState(false);
    const [dataResponsable, setDataResponsable] = useState([]);
    const [dataRequete, setDataRequete] = useState([]);

    function updateOrdre(idO, setO, idR) {
        let newOrdre = idO;
        if (setO === 'up') {
            const moveResponsable = dataResponsable.filter((elem) => elem.ordre === idO - 1);
            ajoutResponsable({ idResponsable: moveResponsable[0].idUtilisateur, newOrdre: moveResponsable[0].ordre + 1 });
            newOrdre = idO - 1;
        } else if (setO === 'down') {
            const moveResponsable = dataResponsable.filter((elem) => elem.ordre === idO + 1);
            ajoutResponsable({ idResponsable: moveResponsable[0].idUtilisateur, newOrdre: moveResponsable[0].ordre - 1 });
            newOrdre = idO + 1;
        }
        ajoutResponsable({ idResponsable: idR, newOrdre: newOrdre });
    }

    function notification(note) {
        props.notification({ message: note.message, status: note.status });
    }

    function PaginationCustom() {
        return <PaginationTable data={props.recherche.fn(dataRequete)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>;
    }

    function Modal() {
        return (
            <DialogCustom
                open={open}
                children={
                    <>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Retour
                        </Button>
                        <h3>Recherche d'un responsable</h3>
                        <TableRecherche tableau={dataRequete} indice={['nomPrenomUtilisateur']}></TableRecherche>
                        <PaginationCustom />
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAjoutResponsable}></TableHeadCustom>
                                <TableBody>
                                    {setData(
                                        props.recherche.fn,
                                        dataRequete,
                                        props.Page[0].page,
                                        props.Page[0].rowsPerPage,
                                        props.orderState,
                                        props.orderByState,
                                    ).map((row, index) => {
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Responsable</div>
                                                    <IconButton
                                                        onClick={() => {
                                                            ajoutResponsable({
                                                                idResponsable: row.idUtilisateur,
                                                                nomResponsable: row.nomPrenomUtilisateur,
                                                            });
                                                        }}
                                                        className={[css.cVert, css.p0, css.mr1]}
                                                        color="primary"
                                                    >
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                    {row.nomPrenomUtilisateur}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Fixe</div>
                                                    {row.telephoneFixe}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Mobile</div>
                                                    {row.telephoneMobile}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationCustom />
                    </>
                }
                fullScreen={true}
            />
        );
    }
    async function getResponsableEleve() {
        let response = await GET(`/api/eleves/${id}/listResponsables`);
        setDataResponsable(response.data);
    }
    async function getListResponsable() {
        props.reponseRecherche({
            fn: (items) => {
                return items;
            },
        });
        const response = await POST('/api/utilisateursResponsables/list/');
        setDataRequete(response.data);
    }
    async function ajoutResponsable(props) {
        let ordreR = props.ordre;
        let roleR = props.role ? props.role : null;
        if (props.newOrdre) {
            const initialRole = dataResponsable.filter((elem) => elem.idUtilisateur === props.idResponsable);
            ordreR = props.newOrdre;
            roleR = initialRole[0].fk_role;
        }
        if (props.nomResponsable) {
            const tableauOrdre = dataResponsable.filter((elem) => elem.idUtilisateur === props.idResponsable);
            ordreR = dataResponsable.length + 1;
            if (tableauOrdre.length > 0) {
                ordreR = tableauOrdre[0].ordre;
            }
        }
        const data = {
            fk_eleve: id,
            fk_responsable: props.idResponsable,
            fk_role: roleR,
            ordre: ordreR,
        };
        await POST('/api/utilisateursResponsables/elevesResponsables', data);
        getResponsableEleve();
        notification({
            message: props.nomResponsable ? `Mme/Mr ${props.nomResponsable} ajouté en tant que responsable` : 'Le responsable a été modifié',
            status: true,
        });
        setOpen(false);
    }

    async function delierResponsable(idResponsable, ordreR) {
        const updateOrdreR = dataResponsable.filter((elem) => elem.ordre > ordreR);
        for (let i = 0; i < updateOrdreR.length; i++) {
            ajoutResponsable({ idResponsable: updateOrdreR[i].idUtilisateur, newOrdre: updateOrdreR[i].ordre - 1 });
        }
        const data = {
            fk_eleve: id,
            fk_responsable: idResponsable,
        };
        await DELETE('/api/utilisateursResponsables/elevesResponsables', data);
        getResponsableEleve();
    }
    useEffect(() => {
        getResponsableEleve();
    }, []);
    return (
        <div>
            {Modal()}
            <h3 className="none xs_block textCenter mt5">Responsable </h3>
            <div className="xs_flex ">
                {testDroit(user[usurpation].roleFonction, fonctionEleve.Ajout_nouveau_responsable) ? (
                    <Link to="/fiche-responsable/0">
                        <Button variant="outlined" size="small">
                            Créer un responsable
                        </Button>
                    </Link>
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionEleve.Associer_un_responsable) ? (
                    <Button
                        variant="outlined"
                        size="small"
                        className={[css.cVert, css.borderCvert, css.ml2]}
                        onClick={() => {
                            setOpen(true);
                            getListResponsable();
                        }}
                    >
                        Associer un responsable
                    </Button>
                ) : null}
            </div>
            <p className="xs_textCenter">Le premier responsable de la liste paye la cotisation</p>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadResponsable} sort={false}></TableHeadCustom>
                    <TableBody>
                        {dataResponsable.map((row, index) => {
                            const buttonDelete = testDroit(user[usurpation].roleFonction, fonctionEleve.Desassocier_responsable) ? (
                                <CustomTooltips title={'Delier ce responsable'}>
                                    <IconButton
                                        onClick={() => {
                                            delierResponsable(row.idUtilisateur, row.ordre);
                                        }}
                                        className={[css.p0, css.floatRight]}
                                        color="secondary"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </CustomTooltips>
                            ) : null;
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>
                                            {matchDisplay ? buttonDelete : null}
                                            Nom Prenom
                                        </div>
                                        {matchDisplay ? null : buttonDelete}
                                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Lien_profil_responsable) ? (
                                            <Link to={`/fiche-responsable/${row.idUtilisateur}`}>{row.nomPrenomUtilisateur}</Link>
                                        ) : (
                                            row.nomPrenomUtilisateur
                                        )}

                                        {row.ordre === 1 ? <EuroIcon className={[css.fontSize1em, css.ml0_3em]} /> : null}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Fixe</div>
                                        {row.telephoneFixe}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Mobile</div>
                                        {row.telephoneMobile}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Role</div>
                                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Gerer_roles) ? (
                                            <FormControl className={[css.w100, css.xs_Left0]}>
                                                <Select
                                                    className={[css.w80, css.xs_Left0]}
                                                    value={row.fk_role ? row.fk_role : ''}
                                                    native={true}
                                                    onChange={(event) =>
                                                        ajoutResponsable({
                                                            role: event.target.value,
                                                            idResponsable: row.idUtilisateur,
                                                            ordre: row.ordre,
                                                        })
                                                    }
                                                >
                                                    <option value={''}></option>
                                                    {rolePMT.map((elem, index) => {
                                                        return (
                                                            <option key={index} value={elem.id}>
                                                                {elem.lib}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        ) : null}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Email</div>
                                        <a href={`mailto:${row.email}`}>{row.email}</a>
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Odre</div>
                                        <IconButton
                                            disabled={row.ordre === 1 ? true : false}
                                            className={[css.cVert, css.p0, css.mr1]}
                                            onClick={() => {
                                                updateOrdre(row.ordre, 'up', row.idUtilisateur);
                                            }}
                                        >
                                            <ExpandLessIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={row.ordre === dataResponsable.length ? true : false}
                                            className={[css.cVert, css.p0, css.mr1]}
                                            onClick={() => {
                                                updateOrdre(row.ordre, 'down', row.idUtilisateur);
                                            }}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponsableEleve);
