/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Button, IconButton } from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { GET, POST } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import euroFormate from '../../../components/Utils/euroFormate';
import downloadFile from '../../../components/Utils/downloadFile';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import calculQuotient from '../../../components/Utils/calculQuotient';
import CardComponent from '../../../components/organism/card/card';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import anneeInscription from '../../../components/Utils/requeteParametre/anneeInscription';
import anneeEnCour from '../../../components/Utils/requeteParametre/anneeEnCour';
import valeurParametre from '../../../components/Utils/requeteParametre/valeurParametre';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadCotisation, tableHeadCotisationDetail } from '../const';

const ResponsableCotisation = (props) => {
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const [open, setOpen] = useState(false);
    const [dataFiscalite, setDataFiscalite] = useState([]);
    const [dataCotisation, setDataCotisation] = useState([]);
    const [abs, setAbs] = useState(false);
    const [dataNewFiscalite, setDataNewFiscalite] = useState({
        income: '',
        parts: '',
        quotient: '',
        tranche: '',
        fileNameReal: '',
        localFileName: '',
    });
    const [uploadFileData, setUploadFileData] = useState();
    const [nameFile, setNameFile] = useState();
    const [infoAnneeInscription, setInfoAnneeInscription] = useState({ libelleAnneeScolaire: '' });
    const [display, setDisplay] = useState({
        cotisation: 'none',
        fiscalite: 'none',
    });
    const [displaySimulation, setDisplaySimulation] = useState('none');
    const [simulation, setSimulation] = useState('<p></p>');

    function CustomButton(props) {
        return (
            <Button size="small" variant="outlined" className={props.className} onClick={props.onClick} color={props.color ? props.color : 'primary'}>
                {props.label}
            </Button>
        );
    }
    function updateState(keys, valueI) {
        setDataNewFiscalite((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function changeDisplay(keys) {
        for (let index = 0; index < Object.keys(display).length; index++) {
            if (display[Object.keys(display)[index]] && Object.keys(display)[index] !== keys) {
                setDisplay((prevState) => ({
                    ...prevState,
                    [Object.keys(display)[index]]: 'none',
                }));
            }
            setDisplay((prevState) => ({
                ...prevState,
                [keys]: display[keys] === 'none' ? 'block' : 'none',
            }));
        }
    }

    function Modal() {
        return (
            <DialogCustom
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                children={
                    <>
                        <div className=" flex mb10px mt10px">
                            <label className="w100 textCenter">Fiscalité pour l'année {infoAnneeInscription.libelleAnneeScolaire}</label>
                        </div>
                        <div className="flex alignICenter ">
                            <Checkbox
                                checked={abs}
                                onChange={() => {
                                    setAbs(!abs);
                                    setNameFile();
                                    setUploadFileData();
                                }}
                            />
                            <span>Je choisis de ne pas transmettre mon avis d'imposition</span>
                        </div>
                        <h2 className="textCenter nomargin">OU</h2>
                        <div className=" flex mb10px mt10px">
                            <label className="w60">Revenu fiscal de référence :</label>
                            <Input
                                type="number"
                                disabled={abs}
                                value={abs === true ? 0 : dataNewFiscalite.income}
                                className={[css.w30, css.mt0]}
                                onChange={(event) => {
                                    updateState('income', event.target.value);
                                }}
                            />
                        </div>
                        <div className=" flex mb10px">
                            <label className="w60">Nombre de part :</label>
                            <Input
                                type="number"
                                disabled={abs}
                                value={abs === true ? 0 : dataNewFiscalite.parts}
                                className={[css.w30, css.mt0]}
                                onChange={(event) => {
                                    updateState('parts', event.target.value);
                                }}
                            />
                        </div>
                        <div className=" flex mb10px">
                            <label className="w60">Quotient familial :</label>
                            <Input
                                disabled={true}
                                value={abs === true ? 0 : calculQuotient(dataNewFiscalite.income, dataNewFiscalite.parts)}
                                className={[css.w30, css.mt0]}
                            />
                        </div>
                        {!abs && (
                            <div className=" flex mb10px">
                                <label className="w60">Avis d'imposition</label>
                                <input
                                    id="raised-button-file"
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        uploadFile(event.target.files[0]);
                                    }}
                                />
                                <label htmlFor="raised-button-file" className="flex directionColumn">
                                    <Button startIcon={<NoteAdd />} className={[css.mt0]} variant="contained" color="default" component="span">
                                        Piece Jointe
                                    </Button>
                                    <label className="mt5 cVert32CD32">{nameFile}</label>
                                </label>
                            </div>
                        )}
                        <div className="mt10 mb5 flex spaceAround">
                            <CustomButton onClick={() => ajoutFiscalite()} type="bouton" label="Valider" />
                            <CustomButton onClick={() => setOpen(false)} color="secondary" type="bouton" label="Annuler" />
                        </div>
                    </>
                }
                maxWidth="lg"
            />
        );
    }
    function TableauCotisation(props) {
        if (!props.tableau) {
            return <></>;
        }
        function returText(index, element) {
            switch (index) {
                case 1:
                    return euroFormate(element);
                case 2:
                    return DateHeureFormat({ date: element });
                default:
                    return element;
            }
        }
        return (
            <Table className={css.BcGrisClair}>
                <TableHeadCustom data={tableHeadCotisationDetail}></TableHeadCustom>
                <TableBody>
                    {props.tableau.split('|-|').map((elem, index) => (
                        <TableRow key={index}>
                            {elem.split('|').map((e, i) =>
                                ![0, 3, 6, 7].includes(i) ? (
                                    <TableCell key={i} component="th" scope="row" className={css.p0}>
                                        <h5 className="ml5">{returText(i, e)}</h5>
                                    </TableCell>
                                ) : null,
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
    function SimulationCotisation() {
        const fiscaliteAnneeInscription = dataFiscalite.filter((e) => +e.fk_anneeScolaire === +infoAnneeInscription.valeur);
        const cotisationAnneEnCour = dataCotisation.filter((e) => +e.fk_anneeScolaire === +infoAnneeInscription.valeur);
        // TODO: MT 230613 remettre le test
        // if (cotisationAnneEnCour.length !== 0) {
        //     return <></>;
        // }
        if (fiscaliteAnneeInscription.length !== 0) {
            return (
                <div>
                    <CustomButton
                        type="bouton"
                        label={`Simuler ma cotisation ${infoAnneeInscription.libelleAnneeScolaire}`}
                        onClick={() => simulationCotisation()}
                    />
                    <div
                        style={{
                            height: '90%',
                            overflow: 'auto',
                            zIndex: '1',
                            display: displaySimulation,
                            position: 'absolute',
                            backgroundColor: '#f3f3f4',
                            padding: '1%',
                            right: '30%',
                            top: '10%',
                        }}
                    >
                        <h2>Ceci est une simulation</h2>
                        Ces chiffres sont indicatifs. Merci de contacter le secrétariat pour plus d'informations
                        <hr />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: simulation,
                            }}
                        ></div>
                        <CustomButton type="bouton" label="Fermer la simulation" onClick={() => setDisplaySimulation('none')} />
                    </div>
                </div>
            );
        }
        return (
            <p>
                {`Pour simuler votre cotisation ${infoAnneeInscription.libelleAnneeScolaire}`}
                <br />
                {`Vous devez ajouter une fiscalité ${infoAnneeInscription.libelleAnneeScolaire}`}
            </p>
        );
    }

    async function libelleAnneeInscription() {
        const response = await anneeInscription();
        setInfoAnneeInscription(response);
    }
    async function uploadFile(file) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            setNameFile();
            setUploadFileData();
            return;
        }
        setNameFile(file.name);
        const data = new FormData();
        data.delete('file');
        data.delete('fk_responsable');
        data.delete('fk_anneeScolaire');
        data.append('file', file);
        data.append('fk_responsable', id);
        data.append('fk_anneeScolaire', infoAnneeInscription.valeur);
        setUploadFileData(data);
    }
    async function recupDataFiscalite() {
        const response = await GET('/api/responsablesRevenus/listFiscalite/' + id);
        setDataFiscalite(response.data);
    }
    async function recupDataCotisation() {
        const response = await GET('/api/cotisations/liste/' + id);
        let cotisationEnCourVisible = await valeurParametre('reglement', 'visuelCotisation');
        if (!+cotisationEnCourVisible) {
            cotisationEnCourVisible = await anneeEnCour();
            for (let index = 0; index < response.data.length; index++) {
                if (response.data[index].fk_anneeScolaire === +cotisationEnCourVisible.id) delete response.data[index];
            }
        }
        setDataCotisation(response.data);
    }
    async function ajoutFiscalite() {
        const data = {
            idRevenu: 0,
            fk_responsable: id,
            fk_anneeScolaire: infoAnneeInscription.valeur,
            revenuFiscal: abs === true ? 0 : dataNewFiscalite.income,
            nombrePart: abs === true ? 0 : dataNewFiscalite.parts,
            quotient: calculQuotient(dataNewFiscalite.income, dataNewFiscalite.parts),
            exoneration: 0,
            absence: abs === true ? 1 : 0,
        };
        for (let index = 0; index < Object.keys(data).length; index++) {
            if (Object.values(data)[index] === '') {
                return props.notification({
                    message: 'Veuillez remplir tous les champs',
                });
            }
        }
        if (!uploadFileData && !abs) {
            return props.notification({
                message: "Veuillez fournir votre avis d'imposition",
            });
        }
        await POST('/api/responsablesRevenus/0', data, {
            redirectOnError: false,
        });
        await POST('/api/fichiers/upload/responsablesRevenus', uploadFileData, {
            upload: true,
            redirectOnError: false,
        });
        setOpen(false);
        setNameFile();
        setAbs(false);
        setDataNewFiscalite({
            income: '',
            parts: '',
            quotient: '',
            tranche: '',
            fileNameReal: '',
            localFileName: '',
        });
        recupDataFiscalite();
        setNameFile();
        setUploadFileData();
    }

    async function simulationCotisation() {
        const date = new Date(new Date(new Date().setMonth(11)).setDate(31)).toLocaleDateString('fr-FR').split('/').reverse().join('-');

        const dataEleve = await GET(`/api/eleves/infoElevePourSimulation?dateIndice=${date}&fk_responsable=${id}`, undefined, {
            redirectOnError: false,
        });
        const dataResponsable = await GET('/api/utilisateursResponsables/' + id);

        const fiscalite = dataFiscalite.filter((e) => +e.fk_anneeScolaire === +infoAnneeInscription.valeur);

        const data = {
            fk_anneeScolaire: infoAnneeInscription.valeur,
            responsable: {
                tranche: fiscalite[0].nombresTranches,
                revenuFiscal: fiscalite[0].revenuFiscal,
                nombrePart: fiscalite[0].nombrePart,
                exoneration: 0,
                codePostal: dataResponsable.data.codePostal,
                ville: dataResponsable.data.ville,
                idUtilisateur: id,
            },
            eleves: dataEleve.data,
        };
        const response = await POST('/api/cotisations/simulation', data, { redirectOnError: false });
        setSimulation(response.data);
        setDisplaySimulation(displaySimulation === 'none' ? 'block' : 'none');
    }

    useEffect(() => {
        libelleAnneeInscription();
        if (dataFiscalite.length === 0) {
            recupDataFiscalite();
        }
        if (dataCotisation.length === 0) {
            recupDataCotisation();
        }
    }, []);

    if (window.matchMedia('(max-width: 576px)').matches) {
        return (
            <CardComponent className={[css.overflowAuto, css.m2, css.mt15, css.h80]} style={{ opacity: '0.9' }}>
                {Modal()}
                <div>
                    <div className="ml2 flex spaceBetween">
                        <h4>Fiscalité </h4>
                        <IconButton onClick={() => changeDisplay('fiscalite')}>{display.fiscalite === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                    </div>
                    <div style={{ display: display.fiscalite }}>
                        <div className="flex alignICenter spaceBetween">
                            <CustomButton
                                className={css.ml2}
                                onClick={() => {
                                    setOpen(true);
                                }}
                                type="bouton"
                                label="Ajouter"
                            />
                        </div>
                        <div>
                            {dataFiscalite.map((row, index) => (
                                <div key={index}>
                                    <hr></hr>
                                    <div className="flex directionColumn ml2">
                                        <span>{`Année scolaire : ${row.libelleAnneeScolaire}`}</span>
                                        <span>{`Revenu fiscal : ${euroFormate(row.revenuFiscal)}`}</span>
                                        <span>{`Nombre parts : ${row.nombrePart}`}</span>
                                        {row.fileName ? (
                                            <Link
                                                to="?cotisation"
                                                onClick={() => {
                                                    downloadFile(
                                                        'responsablesRevenus',
                                                        {
                                                            fk_responsable: id,
                                                            fk_anneeScolaire: row.fk_anneeScolaire,
                                                        },
                                                        row.fileName,
                                                    );
                                                }}
                                            >
                                                {`Fichier : ${row.fileName}`}
                                            </Link>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="ml2 flex spaceBetween">
                        <h4>Cotisation </h4>
                        <IconButton onClick={() => changeDisplay('cotisation')}>{display.cotisation === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                    </div>
                    <div style={{ display: display.cotisation }}>
                        {dataCotisation.map((row, index) => (
                            <div key={index} className="flex directionColumn ml2">
                                <h5 className="textCenter">Montant</h5>
                                <span>{`Montant total : ${euroFormate(row.montantTotal)}`}</span>
                                <span>{`Montant déjà réglé : ${euroFormate(row.MontantDejaRegle)}`}</span>
                                <span>{`Reste à régler : ${euroFormate(row.montantTotal - row.MontantDejaRegle)}`}</span>
                                <h5 className="textCenter">Réglement</h5>
                                <div className="m2">
                                    <TableauCotisation tableau={row.concatCotisation} />
                                </div>
                                <hr className="w100"></hr>
                            </div>
                        ))}
                    </div>
                </div>
            </CardComponent>
        );
    } else {
        return (
            <div className="flex ml7 mt5 mr7  overflowAuto h80" style={{ opacity: '0.9' }}>
                {Modal()}
                <CardComponent className={[css.w25, css.overflowAuto, css.flex, css.directionColumn]}>
                    <div className="flex alignICenter spaceBetween">
                        <h2 className="ml2">Fiscalité</h2>
                        <CustomButton
                            className={css.mr2}
                            onClick={() => {
                                setOpen(true);
                            }}
                            type="bouton"
                            label="Ajouter"
                        />
                    </div>
                    <div>
                        {dataFiscalite.map((row, index) => (
                            <>
                                <hr></hr>
                                <div className="flex directionColumn ml2">
                                    <span>{`Année scolaire : ${row.libelleAnneeScolaire}`}</span>
                                    <span>{`Revenu fiscal : ${euroFormate(row.revenuFiscal)}`}</span>
                                    <span>{`Nombre parts : ${row.nombrePart}`}</span>
                                    {row.fileName ? (
                                        <Link
                                            to="?cotisation"
                                            onClick={() => {
                                                downloadFile(
                                                    'responsablesRevenus',
                                                    {
                                                        fk_responsable: id,
                                                        fk_anneeScolaire: row.fk_anneeScolaire,
                                                    },
                                                    row.fileName,
                                                );
                                            }}
                                        >
                                            {`Fichier : ${row.fileName}`}
                                        </Link>
                                    ) : null}
                                </div>
                            </>
                        ))}
                    </div>
                </CardComponent>
                <CardComponent className={[css.w75, css.overflowAuto, css.pl1, css.pr1]}>
                    <div className="flex alignICenter spaceAround">
                        <h2 className="textCenter w60">Cotisations</h2>
                        {/* <SimulationCotisation /> */}
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            {dataCotisation.length !== 0 ? <TableHeadCustom data={tableHeadCotisation}></TableHeadCustom> : <></>}
                            <TableBody>
                                {dataCotisation.map((row, index) => {
                                    return (
                                        <TableRow className={[css.alignTop, css.textLeft]} key={index}>
                                            <TableCell component="th" scope="row">
                                                <table className="w80 mb10px">
                                                    <tbody>
                                                        <tr className="fontBold">
                                                            <td>{row.libelleAnneeScolaire}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Montant total</td>
                                                            <td className="floatRight">{euroFormate(row.montantTotal)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Montant déjà réglé</td>
                                                            <td className="floatRight">{euroFormate(row.MontantDejaRegle)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reste à régler</td>
                                                            <td className="floatRight">{euroFormate(row.montantTotal - row.MontantDejaRegle)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <TableauCotisation tableau={row.concatCotisation} />
                                            </TableCell>
                                            <TableCell
                                                dangerouslySetInnerHTML={{
                                                    __html: row.explication,
                                                }}
                                            ></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardComponent>
            </div>
        );
    }
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ResponsableCotisation);
