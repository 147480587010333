/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, IconButton, TextareaAutosize } from '@material-ui/core';
import { Switch } from 'antd';

import Input from '../../../components/atoms/TextField';
import { GET, POST } from '../../../components/Utils/requete';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import { Jour } from '../../../components/Utils/consts';
import HeureInput from '../../../components/organism/Input/heure';
import creationTableauFiltre from '../../../components/Utils/createTableauFiltre';
import Reduire from '../../../components/organism/Reduire/Reduire';
import { calculDuree } from '../../../components/Utils/calculTemp';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import Wait from '../../../components/atoms/Wait';
import { testDroit } from '../../../components/Utils/testDroit';
import ListePresences from '../../../components/organism/Presence/Liste';

import AtelierEleve from './Eleve';
import AtelierEnseignants from './Enseignants';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { selectFicheAtelierPart1, selectFicheAtelierPart2, inputFicheAtelierTemp, inputFicheAtelierCommentaire, requeteFiltre } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

export function ButtonCustom(props) {
    const link = function (balise) {
        return props.link ? <Link to={props.link}>{balise}</Link> : <>{balise}</>;
    };
    switch (props.type) {
        case 'bouton':
            return link(
                <Button variant="outlined" size="small" className={props.className} onClick={props.onClick} color={props.color ? props.color : 'primary'}>
                    {props.label ? props.label : 'Valider'}
                </Button>,
            );
        case 'icone':
            return (
                <IconButton disabled={props.disabled} className={props.className} onClick={props.onClick} color={props.color ? props.color : 'primary'}>
                    {props.label}
                </IconButton>
            );
        default:
            break;
    }
    return <></>;
}
const FicheAtelier = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const previousPathURL = useSelector((state) => state.PreviousPath.atelier);
    const valueFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    const nonRoleAdmin = !testDroit(user[usurpation].roleFonction, fonctionAtelier.Enregistrer_atelier);
    const retour = previousPathURL === undefined ? '/accueil' : previousPathURL;
    let { id } = useParams();
    const [dataInput, setDataInput] = useState({
        HeureDebut: '00:00',
        HeureFin: '00:00',
        DureeAtelier: '',
        Commentaire: '',
        Evaluation1: '',
        Evaluation2: '',
        maximumEleves: 0,
        enseignantRemplacant: 0,
    });
    const [tableauFiltre, setTableauFiltre] = useState({
        AnneeScolaire: [],
        Site: [],
        Cycle: [],
        GroupeAtelier: [],
        Annee: [],
        Groupe: [],
        Visibilite: [],
        Repetition: [],
        Statut: [],
        Instrument: [],
        SousGroupeAtelier: [],
        Salle: [],
        Jour: [],
    });
    const [dataEleve, setDataEleve] = useState([]);
    const [tempsAtelier, setTempsAtelier] = useState();
    const [dataFetched, setDataFetched] = useState(false);
    const [individuel, setIndividuel] = useState(false);
    const [atelierType, setAtelierType] = useState([]);
    const [propriete, setPropriete] = useState([]);
    const [dataAtelier, setDataAtelier] = useState([]);

    function updateState(FT, keys, valueI) {
        if (keys === 'HeureFin' && valueI.getTime() < new Date(`01/01/1970 ${dataInput.HeureDebut}`).getTime()) {
            return props.notification({ message: "Vous devez mettre une heure plus avancé que l'heure de début de cours" });
        }
        const state = FT === 'filtre' ? setTableauFiltre : setDataInput;
        state((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));

        if (keys === 'HeureDebut') {
            setDataInput((prevState) => ({
                ...prevState,
                HeureFin: new Date(valueI.getTime() + +dataInput.DureeAtelier * 60000),
            }));
        }
    }

    function disabledHeure(props) {
        let disabled = false;
        if (props.indice === 'HeureFin') {
            disabled = individuel ? nonRoleAdmin : false;
        }
        if (!dataEleve.length) {
            disabled = true;
        }
        return disabled;
    }
    function proprieteAtelierType() {
        // eslint-disable-next-line eqeqeq
        const selectAtelierType = atelierType.filter((e) => e.idTypeAtelier == valueFiltre.SousGroupeAtelier);
        if (selectAtelierType.length > 0) {
            selectAtelierType[0].propriete.push('Repetition');
            selectAtelierType[0].propriete.push('Visibilite');
            setPropriete(selectAtelierType[0].propriete);
        }
    }
    function SelectPropriete() {
        proprieteAtelierType();
        return selectFicheAtelierPart2.map((row) =>
            propriete.includes(row.indice) ? (
                <div className="textRight alignICenter flex mb10px xs_block  xs_textLeft">
                    <label className="w25 xs_inlineBlock">{row.label}</label>
                    <SelectSimple
                        disabled={!testDroit(user[usurpation].roleFonction, fonctionAtelier.Modification_information)}
                        native={true}
                        tableau={tableauFiltre[row.indice]}
                        indice={row.indice}
                        classe={[css.w50, css.nomargin, css.ml30px]}
                    ></SelectSimple>
                </div>
            ) : null,
        );
    }
    async function recupFiltre() {
        if (tableauFiltre.AnneeScolaire.length === 0) {
            for (let elem = 0; elem < requeteFiltre.length; elem++) {
                const requete = await GET(requeteFiltre[elem].url);
                updateState('filtre', Object.keys(tableauFiltre)[elem], requete.data);
            }
        }
        setDataFetched(true);
    }
    async function recupFiltreDynamique() {
        const sousGroupeAtelierFiltre = await POST('/api/ateliers/ateliersTypes', {
            fk_groupeAtelier: ![valueFiltre.GroupeAtelier][0] ? null : [valueFiltre.GroupeAtelier],
        });
        updateState('filtre', 'SousGroupeAtelier', creationTableauFiltre(sousGroupeAtelierFiltre.data, 'idTypeAtelier', 'libelleType', 'libelleGroupe'));
        setAtelierType(sousGroupeAtelierFiltre.data);
        const salleFiltre = await POST('/api/salles/liste', { fk_site: [valueFiltre.Site] });
        updateState('filtre', 'Salle', creationTableauFiltre(salleFiltre.data, 'idSalle', 'libelleSalle', 'libelleSite'));
    }
    async function recupDataAtelier(duplicateID) {
        const realId = duplicateID ? duplicateID : id;
        const response = await GET(`/api/ateliers/${realId}`);
        setDataAtelier(response.data);
        props.addNomPage(`Fiche atelier - ${response.data.libelleType}`);
        const dureeReelle = response.data.individuel
            ? response.data.heureFin === '00:00:00'
                ? response.data.dureeAtelier
                : calculDuree(response.data.heureDebut, response.data.heureFin)
            : calculDuree(response.data.heureDebut, response.data.heureFin);
        setTempsAtelier(dureeReelle);
        const splitHeure = response.data.heureDebut.split(':');
        const calculHeurFin = new Date().setHours(splitHeure[0], splitHeure[1], splitHeure[2]) + +dureeReelle * 60000;
        setDataInput({
            HeureDebut: response.data.heureDebut,
            HeureFin: response.data.individuel ? new Date(calculHeurFin) : response.data.heureFin,
            DureeAtelier: response.data.dureeAtelier,
            Commentaire: response.data.commentaire,
            Evaluation1: response.data.evaluation1,
            Evaluation2: response.data.evaluation2,
            maximumEleves: response.data.maximumEleves,
            enseignantRemplacant: response.data.enseignantRemplacant,
        });
        props.filtre([
            { AnneeScolaire: response.data.fk_anneeScolaire },
            { SousGroupeAtelier: response.data.fk_typeAtelier },
            { GroupeAtelier: response.data.fk_groupeAtelier },
            { Site: response.data.fk_site },
            { Salle: response.data.fk_salle },
            { Jour: response.data.jour },
            { Repetition: response.data.fk_typeRepetition },
            { Visibilite: response.data.fk_visibilite },
            { Cycle: response.data.fk_cycle },
            { Annee: response.data.fk_annee },
            { Groupe: response.data.fk_groupe },
        ]);
        const data = {
            fk_atelier: realId,
        };
        const responseEleve = await POST('/api/ateliers/elevesByAteliers', data);
        setIndividuel(response.data.individuel ? (responseEleve.data.length !== 1 ? false : true) : false);
        setDataEleve(responseEleve.data);
    }
    async function saveAtelier(action) {
        const idAtelier = '0' === id || 'aller' === action ? null : id;
        const data = {
            idAtelier: idAtelier,
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            fk_typeAtelier: valueFiltre.SousGroupeAtelier === '' ? null : valueFiltre.SousGroupeAtelier,
            fk_site: valueFiltre.Site === '' ? null : valueFiltre.Site,
            fk_salle: valueFiltre.Salle === '' ? null : valueFiltre.Salle,
            fk_typeRepetition: valueFiltre.Repetition === '' ? null : valueFiltre.Repetition,
            jour: valueFiltre.Jour === '' ? null : valueFiltre.Jour,
            heureDebut: DateHeureFormat({ BDD: 'heure', date: dataInput.HeureDebut }),
            heureFin: DateHeureFormat({ BDD: 'heure', date: dataInput.HeureFin }),
            fk_visibilite: valueFiltre.Visibilite === '' ? null : valueFiltre.Visibilite,
            fk_cycle: valueFiltre.Cycle === '' ? null : valueFiltre.Cycle,
            fk_annee: valueFiltre.Annee === '' ? null : valueFiltre.Annee,
            fk_groupe: valueFiltre.Groupe === '' ? null : valueFiltre.Groupe,
            commentaire: dataInput.Commentaire,
            evaluation1: dataInput.Evaluation1,
            evaluation2: dataInput.Evaluation2,
            maximumEleves: dataInput.maximumEleves,
            enseignantRemplacant: dataInput.enseignantRemplacant,
        };
        if (!data.fk_anneeScolaire || !data.fk_typeAtelier || !valueFiltre.GroupeAtelier) {
            return props.notification({
                message: "Veuillez remplir les champs année scolaire, groupe atelier et  sous groupe d'ateliers",
            });
        }
        const response = await POST(`/api/ateliers/${id}`, data);
        if (response.success) {
            props.notification({
                message: "L'atelier a été enregistré.",
                status: true,
            });

            switch (action) {
                case 'rester':
                    props.history.push(`/fiche-atelier/${response.data.idAtelier}`);
                    break;
                case 'aller':
                    props.history.push(`/fiche-atelier/${response.data.idAtelier}`);
                    recupDataAtelier(response.data.idAtelier);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        recupFiltre();
        if (id !== '0') {
            recupDataAtelier();
        } else {
            props.addNomPage('nouvel atelier');
            props.removeFilre();
        }
    }, []);
    useEffect(() => {
        recupFiltreDynamique();
    }, [refreshSimple]);
    return (
        <div>
            {!dataFetched ? <Wait /> : null}
            <div className="flex spaceBetween  AlignICenter p2">
                <Link
                    to={retour}
                    onClick={() => {
                        props.savePathAtelier(undefined);
                    }}
                >
                    <Button variant="outlined" color="secondary" className={[css.ml2, css.mt2]}>
                        retour
                    </Button>
                </Link>
                <div className="flex w35">
                    Cours effectué par un enseignant remplacant{' '}
                    <Switch
                        className="ml5"
                        checked={dataInput.enseignantRemplacant ? true : false}
                        onChange={() => updateState('input', 'enseignantRemplacant', dataInput.enseignantRemplacant ? 0 : 1)}
                    />
                </div>
            </div>

            <div className="flex xs_directionColumn m_directionColumn">
                <div className="w100 m2  xs_w90 m_w90">
                    <div className="flex">
                        <h3 className="w100 nomargin">Information générale </h3>
                    </div>
                    <hr></hr>
                    <Reduire>
                        {selectFicheAtelierPart1.map((row) => (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w25 xs_inlineBlock">{row.label}</label>
                                <SelectSimple
                                    disabled={!testDroit(user[usurpation].roleFonction, fonctionAtelier.Modification_information)}
                                    native={true}
                                    tableau={tableauFiltre[row.indice]}
                                    indice={row.indice}
                                    classe={[css.w50, css.nomargin, css.ml30px]}
                                ></SelectSimple>
                            </div>
                        ))}
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w25 xs_inlineBlock">Salle</label>
                            <SelectSimple
                                native={true}
                                tableau={tableauFiltre.Salle}
                                indice={'Salle'}
                                classe={[css.w50, css.nomargin, css.ml30px]}
                            ></SelectSimple>
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w25 xs_inlineBlock">Jour</label>
                            <SelectSimple native={true} tableau={Jour} indice={'Jour'} classe={[css.w50, css.nomargin, css.ml30px]}></SelectSimple>
                        </div>
                        {inputFicheAtelierTemp.map((row) => (
                            <HeureInput
                                styleDiv="textRight alinICenter flex mb10px xs_textLeft"
                                label={row.label}
                                disabled={!individuel ? false : disabledHeure(row)}
                                value={dataInput[row.indice]}
                                className={[css.nomargin, css.ml30px, css.w20]}
                                onChange={(event) => {
                                    updateState('input', row.indice, new Date(event));
                                }}
                                children={row.indice === 'HeureDebut' && tempsAtelier ? <label>Durée: {tempsAtelier} minutes</label> : null}
                            />
                        ))}
                        <SelectPropriete />
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Nombre_eleve) ? (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w25 xs_inlineBlock">Nombre d'élèves</label>
                                <Input
                                    type="number"
                                    value={dataInput.maximumEleves}
                                    className={[css.nomargin, css.ml30px, css.w50]}
                                    onChange={(event) => {
                                        updateState('input', 'maximumEleves', event.target.value);
                                    }}
                                />
                            </div>
                        ) : null}
                    </Reduire>
                    <ListePresences fk_atelier={id} className="mt5" />
                </div>
                <div className="w100 m2  xs_w90 m_w90">
                    <AtelierEnseignants id={id} />
                    <AtelierEleve id={id} dataAtelier={dataAtelier} maximumEleves={dataInput.maximumEleves} />
                    {inputFicheAtelierCommentaire.map((row) => (
                        <div>
                            <h3 className="w100">{row.label}</h3>
                            <hr></hr>
                            <div className="textRight alignICenter flex mb10px pl2">
                                <TextareaAutosize
                                    disabled={nonRoleAdmin}
                                    className={css.w80}
                                    aria-label="minimum height"
                                    rowsMin={5}
                                    placeholder={row.label}
                                    value={dataInput[row.indice]}
                                    onChange={(event) => {
                                        updateState('input', row.indice, event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="xs_flex xs_directionColumn">
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Enregistre_et_quitter) ? (
                    <ButtonCustom
                        link="/liste-ateliers"
                        type="bouton"
                        label="Enregistrer & Quitter"
                        onClick={() => {
                            saveAtelier();
                        }}
                        className={[css.mb2, css.mt2, css.ml5, css.xs_w80]}
                    />
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Enregistrer_atelier) ? (
                    <ButtonCustom
                        type="bouton"
                        label="Enregistrer & Rester"
                        onClick={() => {
                            saveAtelier('rester');
                        }}
                        className={[css.mb2, css.mt2, css.ml5, css.xs_w80]}
                    />
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Duplication_atelier) ? (
                    <ButtonCustom
                        link="/liste-ateliers"
                        type="bouton"
                        label="Dupliquer & aller"
                        onClick={() => {
                            saveAtelier('aller');
                        }}
                        className={[css.mb2, css.mt2, css.ml5, css.xs_w80, css.xs_mb10]}
                    />
                ) : null}
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
        removeFilre() {
            dispatch({ type: 'removeFiltre' });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FicheAtelier);
